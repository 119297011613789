import { ReducerParams } from 'helpers/types';
import { actionTypes as contractActionTypes } from 'ducks/contract';

export const actionTypes = {
  SEND_REQUEST: 'INVESTMENT_INTERMEDIARY_INFO/SEND_REQUEST',
  SEND_SUCCESS: 'INVESTMENT_INTERMEDIARY_INFO/SEND_SUCCES',
  SEND_FAILURE: 'INVESTMENT_INTERMEDIARY_INFO/SEND_FAILURE',
  PRINT_REQUEST: 'INVESTMENT_INTERMEDIARY_INFO/PRINT_REQUEST',
  PRINT_SUCCESS: 'INVESTMENT_INTERMEDIARY_INFO/PRINT_SUCCES',
  PRINT_FAILURE: 'INVESTMENT_INTERMEDIARY_INFO/PRINT_FAILURE',
  SET_INITIAL_VALUES: 'INVESTMENT_INTERMEDIARY_INFO/SET_INITIAL_VALUES',
  UPDATE_MODEL: 'INVESTMENT_INTERMEDIARY_INFO/UPDATE_MODEL',
  STOP_LOADING: 'INVESTMENT_INTERMEDIARY_INFO/STOP_LOADING',
};

type Model = {
  sendDateTime?: string;
  printDateTime?: string;
};

export interface IntermediaryInfoState {
  isLoading: boolean;
  error?: string;
  model: Model;
}

export const initialState = {
  isLoading: false,
  model: {},
};

const intermediaryInfo = (
  state: IntermediaryInfoState = initialState,
  { type, payload }: ReducerParams,
): IntermediaryInfoState => {
  switch (type) {
    case actionTypes.SEND_REQUEST:
    case actionTypes.PRINT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.SEND_SUCCESS:
      return {
        ...state,
        model: {
          ...state.model,
          sendDateTime: payload,
        },
        isLoading: false,
        error: '',
      };
    case actionTypes.PRINT_SUCCESS:
      return {
        ...state,
        model: {
          ...state.model,
          printDateTime: payload,
        },
        isLoading: false,
        error: '',
      };
    case actionTypes.SEND_FAILURE:
    case actionTypes.PRINT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.UPDATE_MODEL:
      return {
        ...state,
        model: {
          ...state.model,
          ...payload,
        },
      };
    case actionTypes.SET_INITIAL_VALUES:
    case contractActionTypes.RESET:
      return initialState;
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default intermediaryInfo;

export const actions = {
  sendRequest: (callback: Function) => ({
    type: actionTypes.SEND_REQUEST,
    callback,
  }),
  sendSuccess: (timestamp: string) => ({
    type: actionTypes.SEND_SUCCESS,
    payload: timestamp,
  }),
  sendFailure: (message?: Error) => ({
    type: actionTypes.SEND_FAILURE,
    payload: message,
  }),
  printRequest: () => ({
    type: actionTypes.PRINT_REQUEST,
  }),
  printSuccess: (timestamp: string) => ({
    type: actionTypes.PRINT_SUCCESS,
    payload: timestamp,
  }),
  printFailure: (message?: Error) => ({
    type: actionTypes.PRINT_FAILURE,
    payload: message,
  }),
  updateModel: (model: Model) => ({
    type: actionTypes.UPDATE_MODEL,
    payload: model,
  }),
  setInitialValues: () => ({
    type: actionTypes.SET_INITIAL_VALUES,
  }),
  stopLoading: () => ({
    type: actionTypes.STOP_LOADING,
  }),
};
