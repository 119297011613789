export const routes = {
  HOME: '/',
  ADMIN: {
    INDEX: '/admin',
    NEWS: '/admin/news',
    NEWS_EDIT: '/admin/news-editor',
    WIKI: '/admin/wiki',
    WIKI_EDIT: '/admin/wiki-editor',
    FUNDS: '/admin/funds',
    INDEXES: '/admin/indexes',
    PACKAGES: '/admin/packages',
    PORTFOLIO: '/admin/portfolio',
    PORTFOLIO_EDIT: '/admin/portfolio-editor',
    PRODUCERS: '/admin/producer',
    RECOMMENDED: '/admin/recommended',
    TARGET_MARKET: '/admin/target-market',
  },
  CONTRACT: {
    INDEX: '/contract',
    CLIENT_SHOW: '/contract/client/show',
    CLIENT_EDIT: '/contract/client/edit',
    SERVICE_SELECTION: '/contract/service-selection',
    TRANSFER_CONTRACTS: '/contract/transfer-contracts',
    INTERMEDIARY: {
      INDEX: '/contract/intermediary',
      INVESTMENT_INTERMEDIARY_INFO: '/contract/intermediary/investment-intermediary-info',
      INVESTMENT_QUESTIONNAIRE: '/contract/intermediary/investment-questionnaire',
      PRODUCTS: '/contract/intermediary/products',
      INVESTMENTS: '/contract/intermediary/investments',
      DOCUMENTATION: '/contract/intermediary/documentation',
      DOCUMENTATION_SIGNATURE: '/contract/intermediary/documentation-signature',
      DOCUMENTATION_AML: '/contract/intermediary/documentation/aml-data',
      DOCUMENTATION_CONSEQ: '/contract/intermediary/documentation/csq-contract',
      DOCUMENTATION_CONSEQ_AI_HI: '/contract/intermediary/documentation/csq-ai-hi-contract',
      DOCUMENTATION_CONSEQ_CLASSIC: '/contract/intermediary/documentation/csq-classic-contract',
      DOCUMENTATION_INVESTIKA: '/contract/intermediary/documentation/investika-contract',
      DOCUMENTATION_AMUNDI_PLT_CLASSIC: '/contract/intermediary/documentation/amundi-plt-classic-contract',
      DOCUMENTATION_AMUNDI_PLT_SERVICE: '/contract/intermediary/documentation/amundi-plt-service-contract',
      DOCUMENTATION_AMUNDI_PLT_PREMIUM: '/contract/intermediary/documentation/amundi-plt-premium-contract',
      DOCUMENTATION_AMUNDI_R3S_CLASSIC: '/contract/intermediary/documentation/amundi-r3s-classic-contract',
      DOCUMENTATION_AMUNDI_R3S_SERVICE: '/contract/intermediary/documentation/amundi-r3s-service-contract',
      DOCUMENTATION_AMUNDI_R3S_PREMIUM: '/contract/intermediary/documentation/amundi-r3s-premium-contract',
      DOCUMENTATION_TESLA: '/contract/intermediary/documentation/tesla-contract',
      DOCUMENTATION_CODYA: '/contract/intermediary/documentation/codya-contract',
      DOCUMENTATION_CLIENT: '/contract/intermediary/documentation/client-data',
      DOCUMENTATION_GENERALI: '/contract/intermediary/documentation/generali-contract',
      DOCUMENTATION_NNIP: '/contract/intermediary/documentation/nnip-contract',
      DOCUMENTATION_SUMMARY: '/contract/intermediary/documentation/summary-record',
      DOCUMENTATION_COLOSSEUM: '/contract/intermediary/documentation/colosseum-contract',
    },
    EXECUTION_ONLY: {
      INDEX: '/contract/execution-only',
      INVESTMENT_INTERMEDIARY_INFO: '/contract/execution-only/investment-intermediary-info',
      INVESTMENTS: '/contract/execution-only/investments',
      DOCUMENTATION: '/contract/execution-only/documentation',
      DOCUMENTATION_SIGNATURE: '/contract/execution-only/documentation-signature',
      DOCUMENTATION_AML: '/contract/execution-only/documentation/aml-data',
      DOCUMENTATION_CONSEQ: '/contract/execution-only/documentation/csq-contract',
      DOCUMENTATION_INVESTIKA: '/contract/execution-only/documentation/investika-contract',
      DOCUMENTATION_AMUNDI: '/contract/execution-only/documentation/amundi-contract',
      DOCUMENTATION_TESLA: '/contract/execution-only/documentation/tesla-contract',
      DOCUMENTATION_CODYA: '/contract/execution-only/documentation/codya-contract',
      DOCUMENTATION_CONTRACT_MODIFICATION: '/contract/execution-only/documentation/contract-modification',
      DOCUMENTATION_CONTRACT_ADDITION: '/contract/execution-only/documentation/contract-addition',
      DOCUMENTATION_CLIENT: '/contract/execution-only/documentation/client-data',
      DOCUMENTATION_GENERALI: '/contract/execution-only/documentation/generali-contract',
      DOCUMENTATION_NNIP: '/contract/execution-only/documentation/nnip-contract',
      DOCUMENTATION_SUMMARY: '/contract/execution-only/documentation/summary-record',
    },
    ADVISORY: {
      INDEX: '/contract/advisory',
      INVESTMENT_INTERMEDIARY_INFO: '/contract/advisory/investment-intermediary-info',
      INVESTMENT_QUESTIONNAIRE: '/contract/advisory/investment-questionnaire',
      TARGETS: '/contract/advisory/targets',
      CURRENT_POSSESSION: '/contract/advisory/current-possession',
      PORTFOLIO_COMPOSITION: '/contract/advisory/portfolio-composition',
      DOCUMENTATION: '/contract/advisory/documentation',
      DOCUMENTATION_SIGNATURE: '/contract/advisory/documentation-signature',
      DOCUMENTATION_AML: '/contract/advisory/documentation/aml-data',
      DOCUMENTATION_CONSEQ: '/contract/advisory/documentation/csq-contract',
      DOCUMENTATION_CONSEQ_CLASSIC: '/contract/advisory/documentation/csq-classic-contract',
      DOCUMENTATION_INVESTIKA: '/contract/advisory/documentation/investika-contract',
      DOCUMENTATION_AMUNDI_PLT_CLASSIC: '/contract/advisory/documentation/amundi-plt-classic-contract',
      DOCUMENTATION_AMUNDI_PLT_SERVICE: '/contract/advisory/documentation/amundi-plt-service-contract',
      DOCUMENTATION_AMUNDI_PLT_PREMIUM: '/contract/advisory/documentation/amundi-plt-premium-contract',
      DOCUMENTATION_AMUNDI_R3S_CLASSIC: '/contract/advisory/documentation/amundi-r3s-classic-contract',
      DOCUMENTATION_AMUNDI_R3S_SERVICE: '/contract/advisory/documentation/amundi-r3s-service-contract',
      DOCUMENTATION_AMUNDI_R3S_PREMIUM: '/contract/advisory/documentation/amundi-r3s-premium-contract',
      DOCUMENTATION_TESLA: '/contract/advisory/documentation/tesla-contract',
      DOCUMENTATION_CODYA: '/contract/advisory/documentation/codya-contract',
      DOCUMENTATION_CLIENT: '/contract/advisory/documentation/client-data',
      DOCUMENTATION_GENERALI: '/contract/advisory/documentation/generali-contract',
      DOCUMENTATION_NNIP: '/contract/advisory/documentation/nnip-contract',
      DOCUMENTATION_SUMMARY: '/contract/advisory/documentation/summary-record',
      DOCUMENTATION_COLOSSEUM: '/contract/advisory/documentation/colosseum-contract',
    },
    EDWARD: {
      INDEX: '/contract/edward',
      INVESTMENT_INTERMEDIARY_INFO: '/contract/edward/investment-intermediary-info',
    },
  },
  MATERIALS: {
    INDEX: '/materials',
    INSTRUMENTS: '/materials/instruments',
    GLOSSARY: '/materials/glossary',
    MARKET: '/materials/market',
    INVESTMENTS: '/materials/investments',
    EMOTIONS: '/materials/emotions',
    CALCULATORS: {
      EXPECTED_VALUE: '/materials/calculators/expected-value',
      MORTGAGE: '/materials/calculators/mortgage',
      REQUIRED_CONTRIBUTION: '/materials/calculators/required-contribution',
      RENT_COMBINED: '/materials/calculators/rent-combined',
      RENT_AVAILABILITY: '/materials/calculators/rent-availability',
      RENT_REQUIRED_RENT: '/materials/calculators/required-rent',
      RENT_INITIAL_AMOUNT: '/materials/calculators/rent-initial-amount',
    },
  },
  REPORTING: {
    INDEX: '/reporting/partners',
    STATISTICS: '/reporting/statistics',
    PARTNER_STATISTICS: '/reporting/:partnerId/statistics',
    PARTNERS: '/reporting/partners',
    PARTNER_PARTNERS: '/reporting/:partnerId/partners',
    CLIENTS: '/reporting/clients',
    PARTNER_CLIENTS: '/reporting/:partnerId/clients',
    CLIENT_STATISTICS: '/reporting/:partnerId/clients/:clientId',
    UNASSIGNED_CONTRACTS: '/reporting/unassigned-contracts',
    PARTNER_UNASSIGNED_CONTRACTS: '/reporting/:partnerId/unassigned-contracts',
  },
  MICROSITE: {
    INDEX: '/microsite',
    INDEX_LINK: '/microsite/:link',
    QUESTIONNAIRE: '/microsite/:link/investment-questionnaire',
    TARGETS: '/microsite/:link/targets',
    SUMMARY: '/microsite/:link/summary',
    POSESSIONS: '/microsite/:link/current-posession',
    END: '/microsite/end',
  },
} as const;
