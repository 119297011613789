import { ReducerParams, ReducerState, ErrorType } from 'helpers/types';

export const actionTypes = {
  CREATE_REQUEST: 'CONTRACT/CREATE_REQUEST',
  CREATE_SUCCESS: 'CONTRACT/CREATE_SUCCES',
  CREATE_FAILURE: 'CONTRACT/CREATE_FAILURE',
  CANCEL_REQUEST: 'CONTRACT/CANCEL_REQUEST',
  CANCEL_SUCCESS: 'CONTRACT/CANCEL_SUCCES',
  CANCEL_FAILURE: 'CONTRACT/CANCEL_FAILURE',
  RESUME_REQUEST: 'CONTRACT/RESUME_REQUEST',
  RESUME_SUCCESS: 'CONTRACT/RESUME_SUCCES',
  RESUME_FAILURE: 'CONTRACT/RESUME_FAILURE',
  RESUME_COMPLETED_REQUEST: 'CONTRACT/RESUME_COMPLETED_REQUEST',
  RESUME_COMPLETED_SUCCESS: 'CONTRACT/RESUME_COMPLETED_SUCCES',
  RESUME_COMPLETED_FAILURE: 'CONTRACT/RESUME_COMPLETED_FAILURE',
  STEP_REQUEST: 'CONTRACT/STEP_REQUEST',
  STEP_SUCCESS: 'CONTRACT/STEP_SUCCES',
  STEP_FAILURE: 'CONTRACT/STEP_FAILURE',
  COMPLETE_REQUEST: 'CONTRACT/COMPLETE_REQUEST',
  COMPLETE_SUCCESS: 'CONTRACT/COMPLETE_SUCCES',
  COMPLETE_FAILURE: 'CONTRACT/COMPLETE_FAILURE',
  REOPEN_REQUEST: 'CONTRACT/REOPEN_REQUEST',
  REOPEN_SUCCESS: 'CONTRACT/REOPEN_SUCCES',
  REOPEN_FAILURE: 'CONTRACT/REOPEN_FAILURE',
  STOP_SIGN_REQUEST: 'CONTRACT/STOP_SIGN_REQUEST',
  STOP_SIGN_SUCCESS: 'CONTRACT/STOP_SIGN_SUCCESS',
  STOP_SIGN_FAILURE: 'CONTRACT/STOP_SIGN_FAILURE',
  REMIND_SIGN_REQUEST: 'CONTRACT/REMIND_SIGN_REQUEST',
  REMIND_SIGN_SUCCESS: 'CONTRACT/REMIND_SIGN_SUCCESS',
  REMIND_SIGN_FAILURE: 'CONTRACT/REMIND_SIGN_FAILURE',
  FINALIZE_REQUEST: 'CONTRACT/FINALIZE_REQUEST',
  FINALIZE_SUCCESS: 'CONTRACT/FINALIZE_SUCCESS',
  FINALIZE_FAILURE: 'CONTRACT/FINALIZE_FAILURE',
  RESET: 'CONTRACT/RESET',
};

type Model = {
  entityId?: string;
  oldQuestionnaire?: boolean;
};

export interface ContractState extends ReducerState {
  model: Model;
}

export const initialState = {
  isLoading: false,
  model: {},
  error: null,
};

const contract = (state: ContractState = initialState, { type, payload }: ReducerParams): ContractState => {
  switch (type) {
    case actionTypes.CREATE_REQUEST:
    case actionTypes.CANCEL_REQUEST:
    case actionTypes.RESUME_REQUEST:
    case actionTypes.RESUME_COMPLETED_REQUEST:
    case actionTypes.STEP_REQUEST:
    case actionTypes.COMPLETE_REQUEST:
    case actionTypes.STOP_SIGN_REQUEST:
    case actionTypes.REMIND_SIGN_REQUEST:
    case actionTypes.FINALIZE_REQUEST:
    case actionTypes.REOPEN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CREATE_SUCCESS:
    case actionTypes.RESUME_COMPLETED_SUCCESS:
    case actionTypes.RESUME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        model: payload,
        error: null,
      };
    case actionTypes.COMPLETE_SUCCESS:
    case actionTypes.STEP_SUCCESS:
    case actionTypes.STOP_SIGN_SUCCESS:
    case actionTypes.REMIND_SIGN_SUCCESS:
    case actionTypes.FINALIZE_SUCCESS:
    case actionTypes.REOPEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case actionTypes.CREATE_FAILURE:
    case actionTypes.CANCEL_FAILURE:
    case actionTypes.RESUME_FAILURE:
    case actionTypes.RESUME_COMPLETED_FAILURE:
    case actionTypes.COMPLETE_FAILURE:
    case actionTypes.STEP_FAILURE:
    case actionTypes.STOP_SIGN_FAILURE:
    case actionTypes.REMIND_SIGN_FAILURE:
    case actionTypes.FINALIZE_FAILURE:
    case actionTypes.REOPEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.CANCEL_SUCCESS:
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default contract;

export const actions = {
  createRequest: (type: string, callback: Function) => ({
    type: actionTypes.CREATE_REQUEST,
    payload: type,
    callback,
  }),
  createSuccess: (contract: any) => ({
    type: actionTypes.CREATE_SUCCESS,
    payload: contract,
  }),
  createFailure: (error: ErrorType) => ({
    type: actionTypes.CREATE_FAILURE,
    payload: error,
  }),
  cancelRequest: (callback: Function) => ({
    type: actionTypes.CANCEL_REQUEST,
    callback,
  }),
  cancelSuccess: () => ({
    type: actionTypes.CANCEL_SUCCESS,
  }),
  cancelFailure: (error: ErrorType) => ({
    type: actionTypes.CANCEL_FAILURE,
    payload: error,
  }),
  resumeRequest: (entityId: string, callback: Function) => ({
    type: actionTypes.RESUME_REQUEST,
    payload: entityId,
    callback,
  }),
  resumeCompletedRequest: (entityId: string, callback: Function) => ({
    type: actionTypes.RESUME_COMPLETED_REQUEST,
    payload: entityId,
    callback,
  }),
  resumeSuccess: (contract: any) => ({
    type: actionTypes.RESUME_SUCCESS,
    payload: contract,
  }),
  resumeCompletedSuccess: (contract: any) => ({
    type: actionTypes.RESUME_COMPLETED_SUCCESS,
    payload: contract,
  }),
  resumeFailure: (error: ErrorType) => ({
    type: actionTypes.RESUME_FAILURE,
    payload: error,
  }),
  resumeCompletedFailure: (error: ErrorType) => ({
    type: actionTypes.RESUME_COMPLETED_FAILURE,
    payload: error,
  }),
  stepRequest: (stepId: string, callback: Function) => ({
    type: actionTypes.STEP_REQUEST,
    payload: stepId,
    callback,
  }),
  stepSuccess: () => ({
    type: actionTypes.STEP_SUCCESS,
  }),
  stepFailure: (error: ErrorType) => ({
    type: actionTypes.STEP_FAILURE,
    payload: error,
  }),
  // TODO: no longer used
  completeRequest: (callback: Function) => ({
    type: actionTypes.COMPLETE_REQUEST,
    callback,
  }),
  completeSuccess: (timestamp: string) => ({
    type: actionTypes.COMPLETE_SUCCESS,
    payload: timestamp,
  }),
  completeFailure: (error: ErrorType) => ({
    type: actionTypes.COMPLETE_FAILURE,
    payload: error,
  }),
  reopenRequest: (callback: Function) => ({
    type: actionTypes.REOPEN_REQUEST,
    callback,
  }),
  reopenSuccess: () => ({
    type: actionTypes.REOPEN_SUCCESS,
  }),
  reopenFailure: (error: ErrorType) => ({
    type: actionTypes.REOPEN_FAILURE,
    payload: error,
  }),
  stopSigningRequest: (callback: Function, failureCallback: Function) => ({
    type: actionTypes.STOP_SIGN_REQUEST,
    callback,
    failureCallback,
  }),
  stopSigningSuccess: () => ({
    type: actionTypes.STOP_SIGN_SUCCESS,
  }),
  stopSigningFailure: (error: ErrorType) => ({
    type: actionTypes.STOP_SIGN_FAILURE,
    payload: error,
  }),
  remindSigningRequest: (callback: Function) => ({
    type: actionTypes.REMIND_SIGN_REQUEST,
    callback,
  }),
  remindSigningSuccess: () => ({
    type: actionTypes.REMIND_SIGN_SUCCESS,
  }),
  remindSigningFailure: (error: ErrorType) => ({
    type: actionTypes.REMIND_SIGN_FAILURE,
    payload: error,
  }),
  finalizeRequest: (callback: Function) => ({
    type: actionTypes.FINALIZE_REQUEST,
    callback,
  }),
  finalizeSuccess: () => ({
    type: actionTypes.FINALIZE_SUCCESS,
  }),
  finalizeFailure: (error: ErrorType) => ({
    type: actionTypes.FINALIZE_FAILURE,
    payload: error,
  }),

  reset: () => ({
    type: actionTypes.RESET,
  }),
};

// selectors

export const getContractId = (state: any) => state.contract.contract.model.entityId;
export const getContract = (state: any) => state.contract.contract;
