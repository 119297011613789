import { Client, ReducerParams, ReducerState, ErrorType } from 'helpers/types';
import { actionTypes as contractActionTypes } from 'ducks/contract';

export const actionTypes = {
  LOAD_REQUEST: 'CLIENT/LOAD_REQUEST',
  RELOAD_REQUEST: 'CLIENT/RELOAD_REQUEST',
  GET_SUCCESS: 'CLIENT/GET_SUCCES',
  GET_FAILURE: 'CLIENT/GET_FAILURE',
  SAVE_REQUEST: 'CLIENT/SAVE_REQUEST',
  SAVE_SUCCESS: 'CLIENT/SAVE_SUCCES',
  SAVE_FAILURE: 'CLIENT/SAVE_FAILURE',
  NEW_REQUEST: 'CLIENT/NEW_REQUEST',
  NEW_SUCCESS: 'CLIENT/NEW_SUCCES',
  NEW_FAILURE: 'CLIENT/NEW_FAILURE',
  SEND_PERSON_AGREEMENT_REQUEST: 'CLIENT/SEND_PERSON_AGREEMENT_REQUEST',
  SEND_PERSON_AGREEMENT_FAILURE: 'CLIENT/SEND_PERSON_AGREEMENT_FAILURE',
  SCAN_PERSON_AGREEMENT_REQUEST: 'CLIENT/SCAN_PERSON_AGREEMENT_REQUEST',
  SCAN_PERSON_AGREEMENT_FAILURE: 'CLIENT/SCAN_PERSON_AGREEMENT_FAILURE',
  CHECK_PERSON_AGREEMENT_REQUEST: 'CLIENT/CHECK_PERSON_AGREEMENT_REQUEST',
  CHECK_PERSON_AGREEMENT_FAILURE: 'CLIENT/CHECK_PERSON_AGREEMENT_FAILURE',
  SET_INITIAL_VALUES: 'CLIENT/SET_INITIAL_VALUES',
};

export interface ClientState extends ReducerState {
  model: Client;
}

export const initialModel = { model: { data: { type: 'PHYSICAL' } } };

export const initialState = {
  isLoading: false,
  model: {},
  error: null,
};

const client = (state: ClientState = initialState, { type, payload }: ReducerParams): ClientState => {
  switch (type) {
    case actionTypes.LOAD_REQUEST:
    case actionTypes.RELOAD_REQUEST:
    case actionTypes.SAVE_REQUEST:
    case actionTypes.NEW_REQUEST:
    case actionTypes.CHECK_PERSON_AGREEMENT_REQUEST:
    case actionTypes.SEND_PERSON_AGREEMENT_REQUEST:
    case actionTypes.SCAN_PERSON_AGREEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_SUCCESS:
    case actionTypes.SAVE_SUCCESS:
    case actionTypes.NEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        model: payload,
        error: null,
      };
    case actionTypes.GET_FAILURE:
    case actionTypes.SAVE_FAILURE:
    case actionTypes.NEW_FAILURE:
    case actionTypes.CHECK_PERSON_AGREEMENT_FAILURE:
    case actionTypes.SEND_PERSON_AGREEMENT_FAILURE:
    case actionTypes.SCAN_PERSON_AGREEMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.SET_INITIAL_VALUES:
    case contractActionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default client;

export const actions = {
  loadRequest: (entityId: string) => ({
    type: actionTypes.LOAD_REQUEST,
    payload: entityId,
  }),
  reloadRequest: () => ({
    type: actionTypes.RELOAD_REQUEST,
  }),
  getSuccess: (client: Client) => ({
    type: actionTypes.GET_SUCCESS,
    payload: client,
  }),
  getFailure: (error: ErrorType) => ({
    type: actionTypes.GET_FAILURE,
    payload: error,
  }),
  saveRequest: (client: Client, strictValidation: boolean, callback: Function) => ({
    type: actionTypes.SAVE_REQUEST,
    payload: client,
    strictValidation,
    callback,
  }),
  saveSuccess: (client: Client) => ({
    type: actionTypes.SAVE_SUCCESS,
    payload: client,
  }),
  saveFailure: (error: ErrorType) => ({
    type: actionTypes.SAVE_FAILURE,
    payload: error,
  }),
  newRequest: (clientType: string, callback: Function) => ({
    type: actionTypes.NEW_REQUEST,
    payload: clientType,
    callback,
  }),
  newSuccess: (client: Client) => ({
    type: actionTypes.NEW_SUCCESS,
    payload: client,
  }),
  newFailure: (error: ErrorType) => ({
    type: actionTypes.NEW_FAILURE,
    payload: error,
  }),
  sendPersonAgreementRequest: (personId: string, callback: Function) => ({
    type: actionTypes.SEND_PERSON_AGREEMENT_REQUEST,
    payload: {
      personId,
    },
    callback,
  }),
  sendPersonAgreementFailure: (error: ErrorType) => ({
    type: actionTypes.SEND_PERSON_AGREEMENT_FAILURE,
    payload: error,
  }),
  scanPersonAgreementRequest: (formData: any, personId: string, callback: Function) => ({
    type: actionTypes.SCAN_PERSON_AGREEMENT_REQUEST,
    payload: {
      formData,
      personId,
    },
    callback,
  }),
  scanPersonAgreementFailure: (error: ErrorType) => ({
    type: actionTypes.SCAN_PERSON_AGREEMENT_FAILURE,
    payload: error,
  }),
  checkPersonAgreementRequest: (personId: string) => ({
    type: actionTypes.CHECK_PERSON_AGREEMENT_REQUEST,
    payload: {
      personId,
    },
  }),
  checkPersonAgreementFailure: (error: ErrorType) => ({
    type: actionTypes.CHECK_PERSON_AGREEMENT_FAILURE,
    payload: error,
  }),
  setInitialValues: () => ({
    type: actionTypes.SET_INITIAL_VALUES,
  }),
};

// selectors

export const getClientId = (state: any) => state.contract.client.model.entityId;
export const getClientCardId = (state: any) => state.contract.client.model.data.rootId;
