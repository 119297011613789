import React from 'react';
import ReactDOM from 'react-dom';

import './Spinner.css';

type SpinnerProps = {
  className?: string;
  message?: string;
  withTimeout?: boolean;
};

type SpinnerState = {
  message?: string;
};

export const SpinnerLocal: React.FC<SpinnerProps> = (props) => (
  <div className="SpinnerLocal">
    <SpinnerHTML {...props} />
  </div>
);

export class SpinnerHTML extends React.PureComponent<SpinnerProps, SpinnerState> {
  timeout?: number;

  static defaultProps: { withTimeout: boolean } = {
    withTimeout: true,
  };

  constructor(props: SpinnerProps) {
    super(props);
    this.state = {
      message: props.message,
    };
  }

  componentDidMount() {
    if (this.props.withTimeout !== false) {
      this.timeout = window.setTimeout(this.showTimeoutMessage, 10000);
    }
  }

  componentWillUnmount() {
    if (this.props.withTimeout !== false && this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  showTimeoutMessage: Function = () => {
    this.setState({
      message: 'Chvilku strpení. Vypadá to, že operace potrvá trochu déle...',
    });
  };

  render(): React.ReactNode {
    return (
      <div className={['Spinner', this.props.className].filter(Boolean).join(' ')}>
        <svg
          className="Spinner__image"
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle className="Spinner__path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
        </svg>
        {this.state.message && <div className="Spinner__message">{this.state.message}</div>}
      </div>
    );
  }
}

export const withSpinner = (Component: any) =>
  class extends React.PureComponent<any, any> {
    state = {
      isLoading: false,
    };

    onClick = async () => {
      this.setState({
        isLoading: true,
      });

      await this.props.onClick();

      this.setState({
        isLoading: false,
      });
    };

    render = () => (
      <React.Fragment>
        {this.state.isLoading ? (
          <SpinnerHTML withTimeout={false} className="Local" />
        ) : (
          <Component {...this.props} onClick={this.onClick} />
        )}
      </React.Fragment>
    );
  };

/**
 * Shows loading effect attached to the #app-content DOM element
 */
export default class Spinner extends React.PureComponent<SpinnerProps> {
  render(): React.ReactNode {
    const appContent = document.getElementById('app-content');

    if (!appContent) return null;

    return ReactDOM.createPortal(<SpinnerHTML {...this.props} />, appContent);
  }
}
