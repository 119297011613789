import React from 'react';

import Spinner from 'components/Spinner/Spinner';
import Message from 'components/Message/Message';

const LoadingComponent = ({ isLoading, error }: { isLoading: boolean; error: boolean }) => {
  if (isLoading) {
    return <Spinner />;
  } else if (error) {
    return <Message type="error" message="Došlo k nepředvídané chybě" />;
  } else {
    return null;
  }
};

export default LoadingComponent;
