import React from 'react';
import { connect } from 'react-redux';

import './AppFooter.css';
import { State } from 'ducks';
import { UserInfo } from '../../helpers/types';

type AppFooterProps = {
  profile: UserInfo;
  user: unknown; // BTS-967: for App
};

/** Used for printing discalimer only */
class AppFooter extends React.PureComponent<AppFooterProps> {
  render() {
    const { profile } = this.props;

    return (
      <footer className="AppFooter">
        <div className="AppFooter__content">
          {profile && profile.name && (
            <div className="AppFooter__content__left">
              Vaším poradcem je: <strong>{profile.name}</strong>
              {profile.email && `, ${profile.email}`}
            </div>
          )}
          <div className="AppFooter__content__right">
            {/* &copy; Broker Trust, a.s. &bull; Hanusova 1411/18, 140 00 Praha 4 */}
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state: State) => ({
  profile: state.profile.model,
});

export default connect(mapStateToProps)(AppFooter);
