import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  scope: 'openid',
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: `${window.location.origin}/callback`,
  response_type: 'token',
  loadUserInfo: false,
  metadata: {
    authorization_endpoint: process.env.REACT_APP_OIDC_AUTHORIZATION_ENDPOINT,
    end_session_endpoint: process.env.REACT_APP_OIDC_END_SESSION_ENDPOINT,
    //userinfo_endpoint: process.env.REACT_APP_OIDC_USERINFO_ENDPOINT
  },
  silent_redirect_uri: `${window.location.origin}/silent_renew.html`,
  automaticSilentRenew: true,
  //silentRequestTimeout: 1000*60*30,
  //accessTokenExpiringNotificationTime: 60*30
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
