import { handleRequest } from 'helpers/handleRequest';
import { actions, getContractId } from 'ducks/contract';
import { getClientId } from 'ducks/client';
import { call, put, select } from 'redux-saga/effects';
import { api } from 'constants/api';

import { actions as clientActions } from 'ducks/client';
import { actions as infoActions } from 'ducks/investmentIntermediaryInfo';
import { actions as questionnaireActions } from 'ducks/investmentQuestionnaire';
import { actions as productsActions } from 'ducks/products';

import { normalizeSelectedProducts } from 'helpers/normalizer';

export function* createContract(action: any): any {
  try {
    const clientId = yield select(getClientId);
    const res = yield call(handleRequest, api.CONTRACT_CREATE, {
      query: {
        type: action.payload,
        client: clientId,
      },
    });
    yield put(actions.createSuccess(res));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.createFailure(e));
  }
}

export function* cancelContract(action: any): any {
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.CONTRACT_CANCEL, {
      method: 'POST',
      query: {
        contract: contractId,
      },
    });
    yield put(actions.cancelSuccess());
    yield put(actions.reset());
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.cancelFailure(e));
  }
}

export function* resumeContract(action: any): any {
  try {
    const res = yield call(handleRequest, api.CONTRACT_RESUME, {
      query: {
        contract: action.payload,
      },
    });

    /* CLIENT */
    if (res.client) {
      yield put(clientActions.getSuccess(res.client));
    }

    /* INVESTMENT_INTERMEDIARY_INFO */
    if (res.information) {
      yield put(infoActions.updateModel(res.information));
    }

    /* INVESTMENT_QUESTIONNAIRE */
    if (res.questionnaire) {
      yield put(questionnaireActions.updateModel(res.questionnaire));
    }

    /* PRODUCTS */
    if (res.selectedProducts) {
      yield put(productsActions.selectProductsSuccess(normalizeSelectedProducts(res.selectedProducts)));
    }

    /* INVESTMENTS - Not needed, page is fetching actual contract data on mount */

    yield put(
      actions.resumeSuccess({
        entityId: res.entityId,
      }),
    );
    if (action.callback) {
      yield call(action.callback, res.step.enumId);
    }
  } catch (e: any) {
    yield put(actions.resumeFailure(e));
  }
}

export function* resumeCompletedContract(action: any): any {
  try {
    const res = yield call(handleRequest, api.CONTRACT_RESUME_COMPLETED, {
      query: {
        contract: action.payload,
      },
    });
    if (res.information) {
      yield put(infoActions.updateModel(res.information));
    }
    if (res.client) {
      yield put(clientActions.getSuccess(res.client));
    }

    yield put(
      actions.resumeCompletedSuccess({
        entityId: res.entityId,
      }),
    );
    if (action.callback) {
      yield call(action.callback, res.step.enumId);
    }
  } catch (e: any) {
    yield put(actions.resumeCompletedFailure(e));
  }
}

export function* step(action: any): any {
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.CONTRACT_STEP, {
      query: {
        id: contractId,
        step: action.payload,
      },
    });
    yield put(actions.stepSuccess());
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.stepFailure(e));
  }
}

export function* completeContract(action: any): any {
  try {
    const contractId = yield select(getContractId);
    const timestamp = yield call(handleRequest, api.CONTRACT_COMPLETE, {
      method: 'POST',
      query: {
        contract: contractId,
      },
    });
    yield put(actions.completeSuccess(timestamp));
    yield put(actions.reset());
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.completeFailure(e));
  }
}

export function* reopenContract(action: any): any {
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.CONTRACT_REOPEN, {
      method: 'POST',
      query: {
        contract: contractId,
      },
    });
    yield put(actions.reopenSuccess());
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.reopenFailure(e));
  }
}

export function* stopSigningContract(action: any): any {
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.CONTRACT_SIGNATURE_CANCEL, {
      method: 'POST',
      query: {
        contract: contractId,
      },
    });
    yield put(actions.stopSigningSuccess());
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.stopSigningFailure(e));
    if (action.failureCallback) {
      yield call(action.failureCallback);
    }
  }
}

export function* remindSigningContract(action: any): any {
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.CONTRACT_SIGNATURE_REMIND, {
      method: 'POST',
      query: {
        contract: contractId,
      },
    });
    yield put(actions.remindSigningSuccess());
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.remindSigningFailure(e));
    if (action.callbackFail) {
      yield call(action.callbackFail);
    }
  }
}

export function* finalizeContract(action: any): any {
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.CONTRACT_FINALIZE, {
      method: 'POST',
      query: {
        contract: contractId,
      },
    });
    yield put(actions.finalizeSuccess());
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.finalizeFailure(e));
  }
}
