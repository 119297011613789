import { handleRequest } from 'helpers/handleRequest';
import { actions, getCountries } from 'ducks/values';
import { call, put, select } from 'redux-saga/effects';
import { api } from 'constants/api';

export function* countries(action: any): any {
  try {
    const countries = yield select(getCountries);
    if (!countries) {
      const values = yield call(handleRequest, api.VALUES_COUNTRIES);
      yield put(actions.countriesSuccess(values));
    }
  } catch (e: any) {
    yield put(actions.countriesFailure(e.message));
  }
}

export function* feeTypes(action: any): any {
  try {
    const values = yield call(handleRequest, api.VALUES_FEE_TYPES);
    yield put(actions.feeTypesSuccess(values));
  } catch (e: any) {
    yield put(actions.feeTypesFailure(e.message));
  }
}

export function* currencies(action: any): any {
  try {
    const values = yield call(handleRequest, api.VALUES_CURRENCIES, { auth: action.auth });
    yield put(actions.currenciesSuccess(values));
  } catch (e: any) {
    yield put(actions.currenciesFailure(e.message));
  }
}

export function* indexes(action: any): any {
  try {
    const values = yield call(handleRequest, api.VALUES_INDEXES);
    yield put(actions.indexesSuccess(values));
  } catch (e: any) {
    yield put(actions.indexesFailure(e.message));
  }
}

export function* producers(action: any): any {
  try {
    const values = yield call(handleRequest, api.TRANSFER_PRODUCERS);
    yield put(actions.producersSuccess(values));
  } catch (e: any) {
    yield put(actions.producersFailure(e.message));
  }
}

export function* frequencies(action: any): any {
  try {
    // todo + '/' + action.payload
    const values = yield call(handleRequest, api.VALUES_FREQUENCY);
    yield put(actions.frequenciesSuccess(values));
  } catch (e: any) {
    yield put(actions.frequenciesFailure(e.message));
  }
}
