import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import { actionTypes as contractTypes } from 'ducks/contract';
import { actionTypes as clientTypes } from 'ducks/client';
import { actionTypes as InvestmentIntermediaryInfoTypes } from 'ducks/investmentIntermediaryInfo';
import { actionTypes as investmentQuestionnaireTypes } from 'ducks/investmentQuestionnaire';
import { actionTypes as valuesTypes } from 'ducks/values';
import { actionTypes as productsTypes } from 'ducks/products';
import { actionTypes as investmentsTypes } from 'ducks/investments';
import { actionTypes as documentsTypes } from 'ducks/documents';
import { actionTypes as profileTypes } from 'ducks/profile';

import * as contractSagas from 'sagas/contract';
import * as clientSagas from 'sagas/client';
import * as investmentIntermediaryInfoSagas from 'sagas/investmentIntermediaryInfo';
import * as investmentQuestionnaireSagas from 'sagas/investmentQuestionnaire';
import * as valuesSaga from 'sagas/values';
import * as productsSagas from 'sagas/products';
import * as investmentsSagas from 'sagas/investments';
import * as profileSagas from 'sagas/profile';
import * as documentsSagas from 'sagas/documents';

import { USER_FOUND, SESSION_TERMINATED } from 'redux-oidc';

export default function* rootSaga(): any {
  yield all([
    takeEvery(USER_FOUND, profileSagas.userInfo),
    takeEvery(SESSION_TERMINATED, profileSagas.signedOut),
    takeEvery(profileTypes.SWITCH_PARTNER_REQUEST, profileSagas.switchPartner),
    takeEvery(contractTypes.CREATE_REQUEST, contractSagas.createContract),
    takeEvery(contractTypes.CANCEL_REQUEST, contractSagas.cancelContract),
    takeEvery(contractTypes.RESUME_REQUEST, contractSagas.resumeContract),
    takeEvery(contractTypes.RESUME_COMPLETED_REQUEST, contractSagas.resumeCompletedContract),
    takeEvery(contractTypes.STEP_REQUEST, contractSagas.step),
    takeEvery(contractTypes.COMPLETE_REQUEST, contractSagas.completeContract),
    takeEvery(contractTypes.REOPEN_REQUEST, contractSagas.reopenContract),
    takeEvery(contractTypes.STOP_SIGN_REQUEST, contractSagas.stopSigningContract),
    takeEvery(contractTypes.REMIND_SIGN_REQUEST, contractSagas.remindSigningContract),
    takeEvery(contractTypes.FINALIZE_REQUEST, contractSagas.finalizeContract),
    takeEvery(clientTypes.LOAD_REQUEST, clientSagas.loadClient),
    takeEvery(clientTypes.RELOAD_REQUEST, clientSagas.reloadClient),
    takeEvery(clientTypes.SAVE_REQUEST, clientSagas.saveClient),
    takeEvery(clientTypes.NEW_REQUEST, clientSagas.newClient),
    takeEvery(clientTypes.SEND_PERSON_AGREEMENT_REQUEST, clientSagas.sendPersonAgreement),
    takeEvery(clientTypes.SCAN_PERSON_AGREEMENT_REQUEST, clientSagas.scanPersonAgreement),
    takeEvery(clientTypes.CHECK_PERSON_AGREEMENT_REQUEST, clientSagas.checkPersonAgreement),

    takeEvery(InvestmentIntermediaryInfoTypes.SEND_REQUEST, investmentIntermediaryInfoSagas.send),
    takeEvery(InvestmentIntermediaryInfoTypes.PRINT_REQUEST, investmentIntermediaryInfoSagas.print),
    // takeEvery(investmentQuestionnaireTypes.EVALUATE_COMPLETED_REQUEST, investmentQuestionnaireSagas.evaluateCompleted),
    takeEvery(investmentQuestionnaireTypes.CREATE_REQUEST, investmentQuestionnaireSagas.create as any),
    takeEvery(investmentQuestionnaireTypes.GET_QUESTION_REQUEST, investmentQuestionnaireSagas.getQuestionData as any),
    takeEvery(investmentQuestionnaireTypes.SEND_ANSWERS_REQUEST, investmentQuestionnaireSagas.sendAnswers as any),
    takeEvery(investmentQuestionnaireTypes.EVALUATE_REQUEST, investmentQuestionnaireSagas.evaluate as any),
    takeEvery(investmentQuestionnaireTypes.EVALUATION_SEND_REQUEST, investmentQuestionnaireSagas.evaluationSend),
    takeEvery(investmentQuestionnaireTypes.EVALUATION_PRINT_DATA_REQUEST, investmentQuestionnaireSagas.evaluationPrint),
    takeEvery(investmentQuestionnaireTypes.EMPTY_SEND_REQUEST, investmentQuestionnaireSagas.emptySend),
    takeEvery(investmentQuestionnaireTypes.EMPTY_PRINT_DATA_REQUEST, investmentQuestionnaireSagas.emptyPrint),

    takeLatest(valuesTypes.COUNTRIES_REQUEST, valuesSaga.countries),
    takeLatest(valuesTypes.FEE_TYPES_REQUEST, valuesSaga.feeTypes),
    takeLatest(valuesTypes.CURRENCIES_REQUEST, valuesSaga.currencies),
    takeLatest(valuesTypes.INDEXES_REQUEST, valuesSaga.indexes),
    takeLatest(valuesTypes.PRODUCERS_REQUEST, valuesSaga.producers),
    takeLatest(valuesTypes.FREQUENCIES_REQUEST, valuesSaga.frequencies),
    takeEvery(productsTypes.SELECT_REQUEST, productsSagas.selectProducts),
    takeEvery(investmentsTypes.GET_REQUEST, investmentsSagas.get),
    takeEvery(investmentsTypes.SAVE_REQUEST, investmentsSagas.save),
    takeEvery(investmentsTypes.REMOVE_REQUEST, investmentsSagas.remove),
    takeEvery(documentsTypes.CLIENT_PRINT_DATA_REQUEST, documentsSagas.clientPrintDocuments),
    takeEvery(documentsTypes.INTERNAL_PRINT_DATA_REQUEST, documentsSagas.internalPrintDocuments),
    takeEvery(documentsTypes.CLIENT_SEND_REQUEST, documentsSagas.clientSendDocuments),
    takeEvery(documentsTypes.PRINT_ALL_REQUEST, documentsSagas.printAllDocuments),
  ]);
}
