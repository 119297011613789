import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'; // sessionStorage
import { combineReducers } from 'redux';
import { UserState, reducer as oidcReducer } from 'redux-oidc';

import contract, { ContractState } from 'ducks/contract';
import client, { ClientState } from 'ducks/client';
import investmentQuestionnaire, { InvestmentQuestionnaireState } from 'ducks/investmentQuestionnaire';
import investmentIntermediaryInfo, { IntermediaryInfoState } from 'ducks/investmentIntermediaryInfo';
import investments, { InvestmentsState } from 'ducks/investments';
import documents, { DocumentsState } from 'ducks/documents';
import profile, { ProfileState } from 'ducks/profile';

import values, { ValuesState } from 'ducks/values';
import products, { ProductsState } from 'ducks/products';

const persistConfig = {
  key: 'root',
  storage,
  debug: true,
  blacklist: ['oidc', 'values'],
};

const investmentQuestionnairePersistConfig = {
  key: 'investmentQuestionnaire',
  storage: storage,
  debug: true,
  blacklist: ['isLoading', 'error', 'questionnaire', 'questionnaireId', 'section'],
};

const rootReducer = combineReducers({
  oidc: oidcReducer,
  profile,
  values,
  contract: combineReducers({
    contract,
    client,
    investmentIntermediaryInfo,
    investmentQuestionnaire: persistReducer(investmentQuestionnairePersistConfig, investmentQuestionnaire),
    products,
    investments,
    documents,
  }),
});

export interface State {
  oidc: UserState | undefined;
  profile: ProfileState;
  values: ValuesState;
  contract: {
    contract: ContractState;
    client: ClientState;
    investmentIntermediaryInfo: IntermediaryInfoState;
    investmentQuestionnaire: InvestmentQuestionnaireState;
    products: ProductsState;
    investments: InvestmentsState;
    documents: DocumentsState;
  };
}

export default persistReducer(persistConfig, rootReducer);

// action creators

// selectors

export const getToken = (state: State) => state.oidc!.user!.access_token;
