import { handleRequest } from 'helpers/handleRequest';
import { actions } from 'ducks/products';
import { getContractId } from 'ducks/contract';
import { call, put, select } from 'redux-saga/effects';
import { api } from 'constants/api';
import { denormalizeSelectedProducts } from 'helpers/normalizer';

export function* selectProducts(action: any): any {
  const requestBody = denormalizeSelectedProducts(action.productGroups, action.data.products);
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.PRODUCTS_SELECT, {
      body: JSON.stringify(requestBody),
      method: 'POST',
      query: {
        contract: contractId,
        profileOverride: action.data.profileOverride,
      },
    });
    yield put(actions.selectProductsSuccess(action.data.products));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.selectProductsFailure(e));
  }
}
