import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import reducer from 'ducks/microsite';
import sagas from 'sagas/microsite';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// compose Enhancer -> https://github.com/jhen0409/react-native-debugger/issues/280#issuecomment-432298556
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// mount it on the Store
export const store = createStore(reducer, composeEnhancer(applyMiddleware(sagaMiddleware)));

// run the saga
sagaMiddleware.run(sagas);

export const persistor = persistStore(store);
