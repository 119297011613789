import Modal from 'components/Modal/Modal';
import React, { Component } from 'react';

type UnsupportedBrowserNoticeProps = {};
type UnsupportedBrowserNoticeState = {
  isReaded?: boolean;
};
type BrowserLinks = {
  chrome: string;
  edge: string;
  opera: string;
};

export default class UnsupportedBrowserNotice extends Component<
  UnsupportedBrowserNoticeProps,
  UnsupportedBrowserNoticeState
> {
  links: BrowserLinks;
  constructor(props: UnsupportedBrowserNoticeProps) {
    super(props);
    this.state = {
      isReaded: false,
    };
    this.links = {
      chrome: 'https://support.google.com/chrome/answer/95346?hl=cs&co=GENIE.Platform%3DDesktop#zippy=',
      edge: 'https://www.microsoft.com/en-us/edge/download?form=MA13FJ',
      opera: 'https://www.opera.com/',
    };
  }

  render() {
    const userAgent = navigator.userAgent;
    const onCloseModal = async () => {
      this.setState({ isReaded: true });
      await sessionStorage.setItem('isReadedReport', 'true');
    };
    return (
      <>
        {userAgent.includes('Firefox') && !this.state.isReaded && (
          <div className="content-box">
            <Modal title="Pozor" onClose={() => onCloseModal()} medium>
              <p>
                Váš prohlížeč není podporovaný a nemusí v něm správně fungovat všechny funkce tohoto nástroje. Pro
                správné fungování využijte prosím některý z prohlížečů na bázi chromia -{' '}
                <a href={this.links.chrome}>Google Chrome</a> , <a href={this.links.edge}>Microsoft Edge</a> ,
                <a href={this.links.opera}> Opera</a> a další. <br />
                <br /> Případně na počítačích Mac můžete využít integrovaný prohlížeč Safari.
              </p>
            </Modal>
          </div>
        )}
      </>
    );
  }

  async componentDidMount() {
    const isReadedReport = await sessionStorage.getItem('isReadedReport');

    this.setState({
      isReaded: isReadedReport === 'true',
    });
  }
}
