import { all, takeEvery } from 'redux-saga/effects';
import { actionTypes as coreDataTypes } from 'ducks/microsite/coreData';
import { actionTypes as investmentQuestionnaireTypes } from 'ducks/microsite/investmentQuestionnaire';
import * as coreDataSagas from 'sagas/microsite/coreData';
import * as investmentQuestionnaireSagas from 'sagas/microsite/investmentQuestionnaire';

export default function* rootSaga(): any {
  yield all([
    takeEvery(coreDataTypes.GET_REQUEST, coreDataSagas.get as any),
    takeEvery(investmentQuestionnaireTypes.CREATE_REQUEST, investmentQuestionnaireSagas.create as any),
  ]);
}
