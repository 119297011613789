import React from 'react';
import Select from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import classnames from 'classnames';
import './Whisperer.css';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

const DEFAULT_PLACEHOLDER = 'Vyberte...';

// Probably bad extend of react-select, fix it if possible
export interface WhispererProps extends StateManagerProps<any, any, any> {
  async?: boolean;
  components?: any;
  input?: any;
  placeholder?: string;
  loadOptions?: AsyncProps<any, any, any>['loadOptions'];
  defaultValue?: string;
  className?: string;
  style?: React.CSSProperties;
  searchable?: boolean;
  clearable?: boolean;
  arrowRenderer?: any;
  labelKey?: string;
  defaultOptions?: boolean;
  searchPromptText?: string;
  valueKey?: string;
}

export default class Whisperer extends React.PureComponent<WhispererProps> {
  render(): React.ReactNode {
    const { async, components, ...props } = this.props;
    const placeholder = props.placeholder || (props.input && props.input.placeholder) || DEFAULT_PLACEHOLDER;

    const commonProps = {
      ...props,
      placeholder,
      loadingPlaceholder: 'Nahrávám...',
      ignoreAccents: false,
      onSelectResetsInput: false,
      input: props.input || {},
      defaultValue: props.defaultValue || '',
      isClearable: true,
      noOptionsMessage: () => 'Nic nenalezeno',
      classNames: {
        container: (state: any) =>
          classnames('Select', {
            isFocused: state.isFocused,
            menuIsOpen: state.menuIsOpen,
            isDisabled: state.isDisabled,
          }),
        control: (state: any) =>
          classnames('Select-control', {
            isFocused: state.isFocused,
            menuIsOpen: state.menuIsOpen,
          }),
        menu: (state: any) => 'Select-menu',
        menuList: (state: any) => 'Select-menuList',
        option: (state: any) =>
          classnames('Select-menuList__option', {
            isSelected: state.isSelected,
          }),
        valueContainer: (state: any) => 'Select-control--valueContainer',
        placeholder: (state: any) =>
          classnames('Select-control--placeholder', {
            isDisabled: state.isDisabled,
          }),
        indicatorsContainer: (state: any) => 'Select-control__indicator',
        dropdownIndicator: (state: any) => 'Select-control__indicator--dropdown',
        clearIndicator: (state: any) => 'Select-control__indicator--clear',
      },
    };

    if (async) {
      return (
        <AsyncSelect
          {...commonProps}
          // components={Object.assign({}, components)}
          components={components}
        />
      );
    } else {
      return <Select {...commonProps} />;
    }
  }
}

/**
 * Pre-formatting data to be usable in whisperer (options and selected value)
 */
type OptionsProps = {
  value: string;
  label?: string;
};

export const formatOptions = (data: any[], options: OptionsProps): any[] | null => {
  if (!data || !data.length) return null;

  return data.map((item) => ({
    value: item[options.value],
    label: item[options.label || options.value],
    ...item,
  }));
};

export const formatOptionValue = (data: any, options: OptionsProps): any | null => {
  if (!data) return null;

  return {
    value: data[options.value],
    label: data[options.label || options.value],
    ...data,
  };
};
