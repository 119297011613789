import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';

import createSagaMiddleware from 'redux-saga';
import { loadUser } from 'redux-oidc';

import reducer from './ducks';
import sagas from './sagas';
import userManager from './helpers/userManager';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// compose Enhancer -> https://github.com/jhen0409/react-native-debugger/issues/280#issuecomment-432298556
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// mount it on the Store
const store = createStore(reducer, composeEnhancer(applyMiddleware(sagaMiddleware)));

// run the saga
sagaMiddleware.run(sagas);

const persistor = persistStore(store);

loadUser(store, userManager);

export { store, persistor };
