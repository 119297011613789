import './Modal.css';
import React from 'react';
import ReactDOM from 'react-dom';

const SELECTOR_CLASS_MODAL_PREFIX = 'Modal';

type ModalProps = {
  children: React.ReactNode;
  onClose?: (e?: React.MouseEvent<HTMLElement>) => void;
  closeable?: boolean;
  title?: string;
  onOpen?: Function;
  noMaxWidth?: boolean;
  medium?: boolean;
  className?: string;
};

export default class Modal extends React.Component<ModalProps> {
  static defaultProps = {
    closeable: true,
    noMaxWidth: false,
  };

  onClose = () => {
    if (this.props.closeable) {
      this.props.onClose && this.props.onClose();
    }
  };

  componentDidMount() {
    this.props.onOpen && this.props.onOpen();
  }

  render(): React.ReactNode {
    const appContent = document.getElementById('app-content');
    if (!appContent) return null;

    return ReactDOM.createPortal(
      <div className={SELECTOR_CLASS_MODAL_PREFIX}>
        <div
          className={`${SELECTOR_CLASS_MODAL_PREFIX}__content`}
          data-nomaxwidth={this.props.noMaxWidth || null}
          data-titleless={this.props.title ? null : true}
          data-contentless={this.props.children ? null : true}
          data-md={this.props.medium || null}
        >
          {this.props.title && <div className={`${SELECTOR_CLASS_MODAL_PREFIX}__title`}>{this.props.title}</div>}
          {this.props.closeable && this.props.onClose && (
            <span className={`${SELECTOR_CLASS_MODAL_PREFIX}__close`} onClick={this.props.onClose} />
          )}
          {this.props.children}
        </div>
      </div>,
      appContent,
    );
  }
}
