import React from 'react';

import Message from 'components/Message/Message';
import Modal from 'components/Modal/Modal';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { store } from 'store';
import { gtmPush } from 'helpers/gtm';
import sessionStorage from 'redux-persist/es/storage/session';

import './AppContent.css';
import UnsupportedBrowserNotice from 'components/UnsupportedBrowserNotice/UnsupportedBrowserNotice';

type AppContentState = {
  error?: string | null;
};

interface AppContentProps extends RouteComponentProps {
  children: React.ReactNode;
}

/** Main content of the app, containing an error boundary */
class AppContent extends React.Component<AppContentProps, AppContentState> {
  unlisten: any;

  constructor(props: AppContentProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({
      error,
    });
  }

  reload() {
    window.location.reload();
  }

  render() {
    return (
      <main className="AppContent" id="app-content">
        <UnsupportedBrowserNotice />
        {this.state.error && (
          <div className="content-box">
            <Message type="error">
              <p>
                Bohužel, došlo k neočekávané chybě.{' '}
                <button className="button-link" onClick={this.reload}>
                  Znovu načíst stránku
                </button>
                .
              </p>
            </Message>
          </div>
        )}
        {this.props.children}
      </main>
    );
  }

  sendGTM = (location?: any) => {
    // store.getState().profile.model.entityId
    if (store.getState().profile.model.username) {
      gtmPush({ event: 'javaPageview', userID: store.getState().profile.model.username!.name });
    }
  };

  async componentDidMount() {
    this.unlisten = this.props.history.listen(this.sendGTM);
    this.sendGTM();
  }

  componentWillUnmount() {
    this.unlisten();
  }
}

export default withRouter(AppContent);
