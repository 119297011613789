import { handleRequest } from 'helpers/handleRequest';
import { getContractId } from 'ducks/contract';
import { actions } from 'ducks/documents';
import { call, put, select } from 'redux-saga/effects';
import { api } from 'constants/api';

export function* clientPrintDocuments(action: any): any {
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.DOCUMENTS_CLIENT_PRINT_DATA, {
      query: {
        contract: contractId,
      },
    });
    const timestamp = yield call(handleRequest, api.DOCUMENTS_CLIENT_PRINT, {
      query: {
        contract: contractId,
      },
    });
    yield put(actions.clientPrintSuccess(timestamp));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.clientPrintFailure(e));
  }
}

export function* internalPrintDocuments(action: any): any {
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.DOCUMENTS_INTERNAL_PRINT_DATA, {
      query: {
        contract: contractId,
      },
    });
    const timestamp = yield call(handleRequest, api.DOCUMENTS_INTERNAL_PRINT, {
      query: {
        contract: contractId,
      },
    });
    yield put(actions.internalPrintSuccess(timestamp));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.internalPrintFailure(e));
  }
}

export function* clientSendDocuments(action: any): any {
  try {
    const contractId = yield select(getContractId);
    const result = yield call(handleRequest, api.DOCUMENTS_CLIENT_SEND, {
      query: {
        contract: contractId,
      },
    });
    yield put(actions.clientSendSuccess(result));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.clientSendFailure(e));
  }
}

export function* printAllDocuments(action: any): any {
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.DOCUMENTS_PRINT_ALL, {
      query: {
        contract: contractId,
      },
    });
    yield put(actions.printAllSuccess());
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.printAllFailure(e));
  }
}
