import React from 'react';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { PersistGate } from 'redux-persist/es/integration/react';
import { store, persistor } from 'store';

import userManager from 'helpers/userManager';
import { Loading, onBeforeLift } from 'helpers/persistorHelpers';

import App from 'containers/common/App/App';

class MainAppRenderer extends React.Component<any, {}> {
  render = () => (
    <Provider store={store}>
      <PersistGate persistor={persistor} onBeforeLift={onBeforeLift} loading={<Loading />}>
        <OidcProvider store={store} userManager={userManager}>
          <App />
        </OidcProvider>
      </PersistGate>
    </Provider>
  );
}

export default MainAppRenderer;
