import React from 'react';
import { Link } from 'react-router-dom';

import Message from 'components/Message/Message';
import { routes } from 'constants/routes';

export default class PageNoPermissions extends React.PureComponent<{}> {
  render() {
    return (
      <div className="content-box">
        <Message type="info">Bohužel, pro přístup na danou stránku nemáte oprávnění.</Message>
        <Link to={routes.HOME} className="back-link">
          Zpět na hlavní stránku
        </Link>
      </div>
    );
  }
}
