import { handleRequest } from 'helpers/handleRequest';
import { call, put } from 'redux-saga/effects';
import { actions } from 'ducks/profile';
import { api } from 'constants/api';
import { persistor } from 'store';
import { paramsToUrlMapper } from 'helpers/helpers';

import userManager from 'helpers/userManager';

export function* userInfo(action: any): any {
  try {
    const profile = yield call(handleRequest, api.USER_GET);
    yield put(actions.getSuccess(profile));
  } catch (e: any) {
    yield put(actions.getFailure(e));
    yield userManager.removeUser();
    if (persistor) {
      yield persistor.purge();
    }
    yield userManager.signoutRedirect();
  }
}

export function* switchPartner(action: any): any {
  try {
    const profile = yield call(handleRequest, paramsToUrlMapper(api.SWITCH_PARTNER, { partnerCode: action.payload }));
    yield put(actions.getSuccess(profile));
  } catch (e: any) {
    yield put(actions.getFailure(e));
  }
}

export function* signedOut(action: any): any {
  try {
    yield persistor.purge();
  } catch (e: any) {
    console.error(e);
  }
}
