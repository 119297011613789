import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { actions } from 'ducks/profile';
import logo from 'images/logo.svg';
import logoPrint from 'images/logo_print.svg';
import { routes } from 'constants/routes';
import userManager from 'helpers/userManager';

import AppHeaderHelpModal from 'components/AppHeader/AppHeaderHelpModal';
import Spinner from 'components/Spinner/Spinner';
import Whisperer from 'components/Whisperer/Whisperer';

import { State } from 'ducks';

import './AppHeader.css';
import { UserInfo, UserPermissions } from '../../helpers/types';

type AppHeaderProps = {
  user: {
    name?: string;
    expired: boolean;
  };
  isLoading: boolean;
  profile: UserInfo;
  switchPartnerRequest: Function;
};

type AppHeaderState = {
  helpModalVisible: boolean;
};

class AppHeader extends React.Component<AppHeaderProps, AppHeaderState> {
  state = {
    helpModalVisible: false,
  };

  logout(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    userManager.signoutRedirect();
  }

  switchPartner = ({ value }: { value: string; label: string }) => {
    this.props.switchPartnerRequest(value);
  };

  toggleHelpModal = () => {
    this.setState({
      helpModalVisible: !this.state.helpModalVisible,
    });
  };

  render() {
    const { user, profile } = this.props;
    const { permissions = {} as UserPermissions } = profile;
    const hideAdminLink_ = !permissions.ipAdmin || process.env.REACT_APP_HIDE_ADMINISTRATION === '1';

    return (
      <header className="AppHeader">
        <div className="AppHeader__content">
          <Link to={routes.HOME} className="AppHeader__logo">
            <img src={logo} alt="Broker Trust" className="AppHeader__logo__image" />
            <img src={logoPrint} alt="Broker Trust" className="AppHeader__logo__image__print" />
          </Link>

          {this.props.isLoading && <Spinner />}
          {user && !user.expired && (
            <div className="AppHeader__user-content">
              {profile && profile.name && (
                <div className="AppHeader__user-info">
                  {profile.name}
                  {profile.partner != null &&
                    profile.partner.partnerCode != null &&
                    ` (${profile.partner.partnerCode})`}
                </div>
              )}

              {!hideAdminLink_ && (
                <Link to={routes.ADMIN.INDEX} className="AppHeader__link fa fa-cog" title="Do administrace">
                  <span>Do administrace</span>
                </Link>
              )}
              {permissions.assistant && profile.associatedPartners && profile.associatedPartners.length > 0 && (
                <div className="AppHeader__assistant">
                  <span className="fa fa-eye" />
                  <Whisperer
                    placeholder={profile.partner!.partnerName}
                    onChange={this.switchPartner}
                    isSearchable={false}
                    isMulti={false}
                    value={profile.partner!.partnerCode}
                    isClearable={false}
                    options={profile.associatedPartners.map((partner) => {
                      return {
                        value: partner.partnerCode,
                        label: partner.partnerName,
                      };
                    })}
                  />
                </div>
              )}
              <button className="AppHeader__link fa fa-question-circle" title="Nápověda" onClick={this.toggleHelpModal}>
                <span>Nápověda</span>
              </button>
              <Link
                to={routes.HOME}
                className="AppHeader__link fa fa-power-off"
                onClick={this.logout}
                title="Odhlásit se"
              >
                <span>Odhlásit se</span>
              </Link>
            </div>
          )}
        </div>
        {this.state.helpModalVisible && <AppHeaderHelpModal toggleHelpModal={this.toggleHelpModal} />}
      </header>
    );
  }
}

const mapStateToProps = (state: State) => ({
  profile: state.profile.model,
  isLoading: state.profile.isLoading,
});

export default connect(mapStateToProps, {
  switchPartnerRequest: actions.switchPartnerRequest,
})(AppHeader);
