import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'; // sessionStorage
import coreData from './coreData';
import investmentQuestionnaire, { InvestmentQuestionnaireState } from './investmentQuestionnaire';

const rootReducer = combineReducers({
  coreData,
  investmentQuestionnaire,
});

const persistConfig = {
  key: 'microsite',
  storage,
  debug: true,
  blacklist: ['values', 'investmentQuestionnaire'],
};

export interface MicrositeState {
  coreData: any;
  investmentQuestionnaire: InvestmentQuestionnaireState;
}

export default persistReducer(persistConfig, rootReducer);
