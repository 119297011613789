import { SESSION_TERMINATED } from 'redux-oidc';

import { ReducerParams, ReducerState, ErrorType } from 'helpers/types';
import { MicrositeState } from '.';

export const actionTypes = {
  GET_REQUEST: 'CORE_DATA/GET_REQUEST',
  GET_SUCCESS: 'CORE_DATA/GET_SUCCES',
  GET_FAILURE: 'CORE_DATA/GET_FAILURE',
  RESET: 'CORE_DATA/RESET',
};

export type PartnerModel = {
  phone: string;
  partnerName: string;
  externalId: string;
  partnerCode: string;
  email: string;
};

export type Model = {
  link: string;
  partner: PartnerModel;
  contractType: string;
  useContract: boolean;
};

export interface CoreDataState extends ReducerState {
  model?: Model;
}

export const initialState = {
  isLoading: false,
  error: null,
};

const coreData = (state: CoreDataState = initialState, { type, payload }: ReducerParams): CoreDataState => {
  switch (type) {
    case actionTypes.GET_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        model: {
          ...state.model,
          ...payload,
        },
      };
    case actionTypes.GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case SESSION_TERMINATED:
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default coreData;

export const actions = {
  getRequest: (link: string) => ({
    type: actionTypes.GET_REQUEST,
    payload: {
      link,
    },
  }),
  getSuccess: (coreData: Model) => ({
    type: actionTypes.GET_SUCCESS,
    payload: coreData,
  }),
  getFailure: (error: ErrorType) => ({
    type: actionTypes.GET_FAILURE,
    payload: error,
  }),
  reset: () => ({
    type: actionTypes.RESET,
  }),
};

export const coreDataModelSelector = (state: MicrositeState): Model => state.coreData && state.coreData.model;
export const coreDataErrorSelector = (state: MicrositeState): ErrorType | undefined =>
  state.coreData && state.coreData.error;
export const coreDataContractTypeSelector = (state: MicrositeState): string | undefined =>
  state.coreData && state.coreData.model && state.coreData.model.contractType;
export const coreDataLinkSelector = (state: MicrositeState): string | undefined =>
  state.coreData && state.coreData.model && state.coreData.model.link;
export const coreDataPartnerSelector = (state: MicrositeState): PartnerModel =>
  state.coreData && state.coreData.model && state.coreData.model.partner;
export const coreDataUseContractSelector = (state: MicrositeState): boolean | undefined =>
  state.coreData && state.coreData.model && state.coreData.model.useContract;
