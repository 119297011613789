import { ReducerParams } from 'helpers/types';
import { actionTypes as contractActionTypes } from 'ducks/contract';

export const actionTypes = {
  GET_REQUEST: 'INVESTMENTS/GET_REQUEST',
  GET_SUCCESS: 'INVESTMENTS/GET_SUCCES',
  GET_FAILURE: 'INVESTMENTS/GET_FAILURE',
  SAVE_REQUEST: 'INVESTMENTS/SAVE_REQUEST',
  SAVE_FAILURE: 'INVESTMENTS/SAVE_FAILURE',
  REMOVE_REQUEST: 'INVESTMENTS/REMOVE_REQUEST',
  REMOVE_FAILURE: 'INVESTMENTS/REMOVE_FAILURE',
};

export type Model = {
	investments?: Object, // internal
	isComplete?: boolean
}

export type InvestmentsState = {
  isLoading: boolean;
  error?: string;
  model: Model;
};

export const initialState = {
  isLoading: false,
  model: {},
};

const investments = (state: InvestmentsState = initialState, { type, payload }: ReducerParams): InvestmentsState => {
	switch (type) {
		case actionTypes.GET_REQUEST:
		case actionTypes.REMOVE_REQUEST:
		case actionTypes.SAVE_REQUEST:
			return {
				...state,
				isLoading: true,
				error: ''
			}
		case actionTypes.GET_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: '',
				model: {
					...state.model,
					investments: payload.investments,
					isComplete: payload.isComplete
				}
			}
		case actionTypes.GET_FAILURE:
		case actionTypes.REMOVE_FAILURE:
		case actionTypes.SAVE_FAILURE:
			return {
				...state,
				isLoading: false,
				error: payload
			}
		case contractActionTypes.RESET:
			return initialState
		default:
			return state
	}
}

export default investments;

export const actions = {
  getRequest: () => ({
    type: actionTypes.GET_REQUEST,
  }),
  getSuccess: (investments: Object) => ({
    type: actionTypes.GET_SUCCESS,
    payload: investments,
  }),
  getFailure: (message?: string) => ({
    type: actionTypes.GET_FAILURE,
    payload: message,
  }),
  saveRequest: (investment: Object, callback: Function) => ({
    type: actionTypes.SAVE_REQUEST,
    investment,
    callback,
  }),
  saveFailure: (message?: string) => ({
    type: actionTypes.SAVE_FAILURE,
    payload: message,
  }),
  removeRequest: (entityId: string) => ({
    type: actionTypes.REMOVE_REQUEST,
    entityId,
  }),
  removeFailure: (message?: string) => ({
    type: actionTypes.REMOVE_FAILURE,
    payload: message,
  }),
};
