import { store } from 'store';

import { paramsToUrlMapper, objectToQueryString } from 'helpers/helpers';

export const fullUrl = (url: string): string => `${String(process.env.REACT_APP_API_URL)}${url}`;

const parseJSONError = (response: Response): Promise<any> => {
  return new Promise((resolve, reject) => {
    // try to parse the JSON before erroring
    response
      .json()
      .then((data) => {
        let error = data;
        if (!error.message) {
          error.message = response.statusText;
        }
        error.private = true;
        error.status = response.status;
        reject(error);
      })
      .catch((err) => {
        // there was an error trying to parse the JSON body (maybe it's not JSON?)
        // just ignore it and return an error with the original response without a parsed body
        let error = new Error(response.statusText);
        (error as any).response = response;
        reject(response);
      });
  });
};

const checkStatus = (response: Response): any => {
  return response.ok ? response : parseJSONError(response);
};

const downloadPDF = (blob: Blob, headers: Headers) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: 'application/pdf' });

  const disposition = headers.get('content-disposition');
  let filename = 'download.pdf';
  if (disposition) {
    const parsed = /filename="(.+.pdf)"/.exec(disposition);
    if (parsed && parsed[1]) {
      filename = decodeURIComponent(parsed[1]);
    }
  }

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, filename);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const body = document.body;
  let link = document.createElement('a');
  link.href = data;
  link.download = filename;
  if (body) {
    body.appendChild(link);
  }
  link.click();

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    if (body) {
      body.removeChild(link);
    }
    window.URL.revokeObjectURL(data);
  }, 100);
};

const handleResponse = (response: Response): any => {
  const contentType = response.headers.get('content-type');

  if (contentType && contentType.includes('application/json')) {
    return response.json();
  } else if (contentType && contentType.includes('application/pdf')) {
    response.blob().then((b) => downloadPDF(b, response.headers));
  } else {
    throw new Error(`Content-type ${contentType} is not supported`);
  }
};

type RequestOptions = {
  auth?: boolean;
  body?: any;
  headers?: Object;
  method?: string;
  params?: Object;
  query?: Object;
  setContentType?: boolean;
  mock?: boolean;
};

export const handleRequest = (url: string, options?: RequestOptions): Promise<any> => {
  options = {
    auth: true,
    method: 'GET',
    ...options,
  };

  const headers = new Headers();

  if (options.params) {
    url = paramsToUrlMapper(url, options.params);
  }

  if (options.query) {
    url = objectToQueryString(options.query, url);
  }

  if (options.auth) {
    const token = store.getState().oidc.user!.access_token;
    headers.set('Authorization', `Bearer ${token}`);
  }

  if (options.headers) {
    Object.entries(options.headers).forEach(([key, value]) => {
      headers.append(key, value);
    });
  }

  if (
    !headers.get('Content-Type') &&
    // allow fetch to determine content type, otherwise it breaks multipart/data-form types (files)
    options.setContentType !== false
  ) {
    headers.set('Content-Type', 'application/json');
  }

  if (options.mock) {
    headers.set('Access-Control-Allow-Origin', 'http://localhost:80');
  }

  const fetchOptions = {
    headers,
    method: options.method,
    body: options.body,
  };

  return (
    fetch(options.mock ? url : fullUrl(url), fetchOptions)
      //return fetch(fullUrl(url), fetchOptions)
      .then(checkStatus)
      .then(handleResponse)
      .catch((error) => {
        return new Promise((resolve, reject) => {
          // our formatted error from the parseJSONError function
          if (error.private) {
            reject(error);
          } else {
            reject({ message: 'Došlo k chybě při komunikaci se vzdálenými systémy' });
          }
        });
      })
  );
};
