import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// Styles
import 'font-awesome/css/font-awesome.css';
import 'react-tippy/dist/tippy.css';
import './index.css';

import MainAppRenderer from 'containers/common/MainAppRenderer/MainAppRenderer';
import MicrositeAppRenderer from 'containers/common/MicrositeAppRenderer/MicrositeAppRenderer';

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENABLED,
  REACT_APP_SENTRY_DEBUG,
  REACT_APP_SENTRY_TRACE_SAMPLE_RATE,
  REACT_APP_SENTRY_ENVIROMENT,
  REACT_APP_PACKAGE,
} = process.env;

// enable sentry only for Stage, production or on demand for sesstionStorage
const enableSentry =
  !['DEV', 'LOCAL', 'STAGE'].includes(REACT_APP_SENTRY_ENVIROMENT!) || sessionStorage.getItem('sentry') === '1';

enableSentry &&
  Sentry.init({
    enabled: REACT_APP_SENTRY_ENABLED ? (REACT_APP_SENTRY_ENABLED as unknown as boolean) : false,
    dsn: REACT_APP_SENTRY_DSN,
    debug: REACT_APP_SENTRY_DEBUG as unknown as boolean,
    environment: REACT_APP_SENTRY_ENVIROMENT,
    tracesSampleRate: REACT_APP_SENTRY_TRACE_SAMPLE_RATE as unknown as number,
    integrations: [new BrowserTracing()],
    release: REACT_APP_PACKAGE,
  });

const isMicrosite = () => window.location.pathname.match(/^\/microsite/gi);

const render = () => {
  ReactDOM.render(isMicrosite() ? <MicrositeAppRenderer /> : <MainAppRenderer />, document.getElementById('root'));
};

render();

if (module.hot) {
  module.hot.accept('containers/common/App/App', () => {
    const NextApp = require('containers/common/App/App').default;
    (render as any)(NextApp); // ???
  });
}
