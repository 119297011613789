import { routes } from 'constants/routes';
import productsPortfolio from 'images/products/products_portfolio.svg';
import productsLifeCycle from 'images/products/products_life_cycle_fondy.svg';
import productsFund1 from 'images/products/products_fond_fondu.svg';
import productsFund2 from 'images/products/products_podilovy_fond.svg';

export const contractBreadCrumbsMap = new Map([
	[
		'DEFAULT',
		[
			{
				id: 'HOME',
				title: 'Úvod',
				navigateTo: routes.HOME
			},
			{
				id: 'CLIENT',
				title: 'Výběr klienta'
			},
			{
				id: 'SERVICE_SELECTION',
				title: 'Výběr služby'
			}
		]
	],
	[
		'EXECUTION_ONLY',
		[
			{
				id: 'E_INFORMATION',
				title: 'Informace o IZ'
			},
			{
				id: 'E_PARAMETERS',
				title: 'Parametry investice'
			},
			{
				id: 'E_DOCUMENTATION',
				title: 'Smluvní údaje'
			},
			{
				id: 'E_SIGNATURE',
				title: 'Podpis'
			}
		]
	],
	[
		'INTERMEDIARY',
		[
			{
				id: 'I_INFORMATION',
				title: 'Informace o IZ'
			},
			{
				id: 'I_QUESTIONNAIRE',
				title: 'Investiční dotazník'
			},
			{
				id: 'I_PRODUCT_SELECTION',
				title: 'Přiměřené produkty'
			},
			{
				id: 'I_PARAMETERS',
				title: 'Parametry investice'
			},
			{
				id: 'I_DOCUMENTATION',
				title: 'Smluvní údaje'
			},
			{
				id: 'I_SIGNATURE',
				title: 'Podpis'
			}
		]
	],
	[
		'ADVISORY',
		[
			{
				id: 'A_INFORMATION',
				title: 'Informace o IZ'
			},
			{
				id: 'A_QUESTIONNAIRE',
				title: 'Investiční dotazník'
			},
			{
				id: 'A_TARGETS',
				title: 'Definice cíle'
			},
			{
				id: 'A_CURRENT_POSSESSION',
				title: 'Současný majetek'
			},
			{
				id: 'A_PORTFOLIO_COMPOSITION',
				title: 'Tvorba portfolia'
			},
			{
				id: 'A_DOCUMENTATION',
				title: 'Smluvní údaje'
			},
			{
				id: 'A_SIGNATURE',
				title: 'Podpis'
			}
		]
	],
	[
		'TRANSFER_CONTRACTS',
		[
			{
				id: 'TRANSFER_CONTRACTS',
				title: 'Převod smluv do BT'
			}
		]
	],
	[
		'EDWARD',
		[
			{
				id: 'W_INFORMATION',
				title: 'Informace o IZ'
			},
		]
	]

]);

export const highchartsConfig = {
  chart: {
    style: {
      fontFamily: '"Open Sans", Arial, sans-serif',
    },
  },
  lang: {
    months: [
      'leden',
      'únor',
      'březen',
      'duben',
      'květen',
      'červen',
      'červenec',
      'srpen',
      'září',
      'říjen',
      'listopad',
      'prosinec',
    ],
    shortMonths: ['led', 'úno', 'bře', 'dub', 'kvě', 'čer', 'čnc', 'srp', 'zář', 'říj', 'lis', 'pro'],
    numericSymbols: [' tis.', ' mil.', ' mld.'],
  },
  dateTimeLabelFormats: {
    day: '%e. %b',
    hour: '%H:%M',
    millisecond: '%H:%M:%S.%L',
    minute: '%H:%M',
    month: '%b %y',
    second: '%H:%M:%S',
    week: '%e. %b',
    year: '%Y',
  },
  colors: [
    '#324999',
    '#4e296e',
    '#999999',
    '#d30037',
    '#2FAD89',
    '#9791ba',
    '#058DC7',
    '#3e5266',
    '#50B432',
    '#ED561B',
    '#DDDF00',
    '#24CBE5',
    '#64E572',
    '#FF9655',
    '#FFF263',
    '#6AF9C4',
  ],
  newColors: [
    '#245093',
    '#4473b6',
    '#5483c5',
    '#4f306a',
    '#72528d',
    '#886e9e',
    '#dc1f4d',
    '#f94377',
    '#f986a7',
    '#bfbfbf',
    '#d8d8d8',
  ],
};

export const materialsCalculatorsTabs = [
  {
    id: 'EXPECTED_VALUE',
    title: 'Hodnota investice',
    link: routes.MATERIALS.CALCULATORS.EXPECTED_VALUE,
  },
  {
    id: 'REQUIRED_CONTRIBUTION',
    title: 'Měsíční investice',
    link: routes.MATERIALS.CALCULATORS.REQUIRED_CONTRIBUTION,
  },
  {
    id: 'MORTGAGE',
    title: 'Hypotéky a investice',
    link: routes.MATERIALS.CALCULATORS.MORTGAGE,
  },
  {
    id: 'RENT_COMBINED',
    title: 'Měsíční investice pro výplatu renty',
    link: routes.MATERIALS.CALCULATORS.RENT_COMBINED,
  },
  {
    id: 'RENT_AVAILABILITY',
    title: 'Doba čerpání renty',
    link: routes.MATERIALS.CALCULATORS.RENT_AVAILABILITY,
  },
  {
    id: 'RENT_REQUIRED_RENT',
    title: 'Požadovaná renta',
    link: routes.MATERIALS.CALCULATORS.RENT_REQUIRED_RENT,
  },
  {
    id: 'RENT_INITIAL_AMOUNT',
    title: 'Částka pro výplatu renty',
    link: routes.MATERIALS.CALCULATORS.RENT_INITIAL_AMOUNT,
  },
];

export const clientTypes = {
  PHYSICAL: 'PHYSICAL',
  LEGAL: 'LEGAL',
};

export const stepToRoute = {
  I_INFORMATION: routes.CONTRACT.INTERMEDIARY.INVESTMENT_INTERMEDIARY_INFO,
  I_QUESTIONNAIRE: routes.CONTRACT.INTERMEDIARY.INVESTMENT_QUESTIONNAIRE,
  I_PRODUCT_SELECTION: routes.CONTRACT.INTERMEDIARY.PRODUCTS,
  I_PARAMETERS: routes.CONTRACT.INTERMEDIARY.INVESTMENTS,
  I_DOCUMENTATION: routes.CONTRACT.INTERMEDIARY.DOCUMENTATION,
  I_SIGNATURE: routes.CONTRACT.INTERMEDIARY.DOCUMENTATION_SIGNATURE,
  E_INFORMATION: routes.CONTRACT.EXECUTION_ONLY.INVESTMENT_INTERMEDIARY_INFO,
  E_PARAMETERS: routes.CONTRACT.EXECUTION_ONLY.INVESTMENTS,
  E_DOCUMENTATION: routes.CONTRACT.EXECUTION_ONLY.DOCUMENTATION,
  E_SIGNATURE: routes.CONTRACT.EXECUTION_ONLY.DOCUMENTATION_SIGNATURE,
  A_INFORMATION: routes.CONTRACT.ADVISORY.INVESTMENT_INTERMEDIARY_INFO,
  A_QUESTIONNAIRE: routes.CONTRACT.ADVISORY.INVESTMENT_QUESTIONNAIRE,
  A_TARGETS: routes.CONTRACT.ADVISORY.TARGETS,
  A_CURRENT_POSSESSION: routes.CONTRACT.ADVISORY.CURRENT_POSSESSION,
  A_PORTFOLIO_COMPOSITION: routes.CONTRACT.ADVISORY.PORTFOLIO_COMPOSITION,
  A_DOCUMENTATION: routes.CONTRACT.ADVISORY.DOCUMENTATION,
  A_SIGNATURE: routes.CONTRACT.ADVISORY.DOCUMENTATION_SIGNATURE,
  W_INFORMATION: routes.CONTRACT.EDWARD.INVESTMENT_INTERMEDIARY_INFO,
};

export const productCategories = [
  { FUNDS: 'Smíšené a fondy fondů' },
  { INVESTMENT: 'Investiční programy a životní cyklus' },
  { PORTFOLIO: 'Portfolio' },
  { LIFECYCLE: 'Dluhopisové fondy' },
  { OTHER: 'Akciové fondy' },
  { MIXED: 'Nemovitostní a alternativní fondy' },
];

export const producers = {
  AMUNDI: 'AMUNDI',
  ATLANTIK: 'ATLANTIK',
  AXA: 'AXA',
  COLOSSEUM: 'COLOSSEUM',
  CSQ: 'CSQ',
  CYRUS: 'CYRRUS',
  DRFG: 'DRFG',
  GENERALI: 'GENERALI',
  IAD: 'IAD',
  INVESTIKA: 'INVESTIKA',
  JAT: 'JAT',
  MOVENTUM: 'MOVENTUM',
  NN: 'NNIP',
  TESLA: 'TESLA',
};

export const docCategory = {
  DOCUMENTATION: 'documentation',
  ATTACHMENT: 'attachment',
  AML: 'aml',
};

export const uneditableDocTypes = ['QUESTIONNAIRE', 'EVALUATION', 'DATA_PROTECTION', 'CONTRACT_ADDITION'];

export const wikiEditorTypes = {
  RECORD: 'record',
  CATEGORY: 'category',
};

export const dayInMS = 24 * 60 * 60 * 1000;

export const listPageSize = 10;

export const portfolioAssets = [
  { VALUED_STOCKS: 'Akcie - hodnotové' },
  { DIVIDEND_STOCKS: 'Akcie - dividendové/nízká kolísavost' },
  { EMERGING_MARKET_STOCKS: 'Akcie - rozvíjející se trhy' },
  { GROWING_STOCKS: 'Akcie - růstové' },
  { CONSERVATIVE_BONDS: 'Dluhopisy - konzervativní přístup' },
  { EMERGING_MARKET_BONDS: 'Dluhopisy - státní - rozvíjející se trhy' },
  { CORPORATE_BONDS: 'Korporátní dluhopisy' },
  { REAL_ESTATE_FUNDS: 'Nemovitostní fondy' },
  { COMMODITIES: 'Komodity nebo drahé kovy' },
];

export const productsTooltips: Record<string, { text: string; image?: string }> = {
  'Smíšené a fondy fondů': {
    text: 'Tento typ produktu Vám umožní maximální diverzifikaci napříč třídami aktiv, kontinenty a podobně. V rámci fondu fondů si může správce vždy vybrat nejlepšího správce aktiv na daný segment. V portfoliu tak můžou být například akciové strategie od NN a Fidelity. O dluhopisy se pak postará například Franklin Templeton a Amundi. Toto může být optimální řešení pro někoho, kdo si nechce stavět portfolio z jednotlivých fondů sám.',
    image: productsFund1,
  },
  Portfolio: {
    text: 'Myšlenkou portfolia je rozložit Váš majetek mezi třídy aktiv, sektory na trhu a podobně, tak aby se zajistilo výrazně vyšší bezpečí Vaší investice. Když se nebude dařit energetice, tak to mohou vyvážit akcie automobilek anebo třeba výnosy z nemovitostí a dluhopisů. Váhy jednotlivých tříd aktiv záleží na Vašem rizikovém profilu a požadovaném výnosu.',
    image: productsPortfolio,
  },
  'Nemovitostní a alternativní fondy': {
    text: 'Smíšené fondy jsou fondy, které mohou investovat do různých tříd aktiv. Nejsou omezené na jednu konkrétní třídu aktiv. Výsledkem tedy může být diverzifikované portfolio, které v rámci 1 fondu vybere ty nejlepší akcie a dluhopisy z trhu. Toto může být optimální řešení pro někoho, kdo si nechce stavět portfolio z jednotlivých fondů sám.',
    image: productsPortfolio,
  },
  'Dluhopisové fondy': {
    text: 'Máte dlouhý investiční horizont (např. chcete si spořit na důchod) a hledáte jednoduché a elegantní řešení o které se nebudete muset starat? Fondy životního cyklu mohou být skvělou volbou. Tak, jak se blíží konec programu, tak začnou postupně ubírat rizikovou akciovou složku a začnou se přesouvat do konzervativnějších dluhopisů, tak aby Vám investice méně kolísala s blížícím se koncem Vašeho produktu. Vše se děje automaticky.',
    image: productsLifeCycle,
  },
  'Investiční programy a životní cyklus': {
    text: 'Jsou kombinací portfolia a programů životního cyklu. Podle nastavení programu můžete mít postupné zkonzervativňování buď zapnuté nebo vypnuté, případně je možné využít investičního programu jako je Generali Můj Investor+, který udělá program životního cyklu i z jednorázové investice.',
  },
  'Akciové fondy': {
    text: 'V této kategorii najdete další zajímavé akciové, dluhopisové, nemovitostní a další fondy z nabídky BT. Tyto fondy mohou sloužit jak jako doplněk k dalšímu portfoliu fondů, tak i samostatně. Podílové fondy jako takové mají oddělené vlastnictví od správce a jsou dozorovány nejen ČNB.',
    image: productsFund2,
  },
};
