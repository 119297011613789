import { ErrorType, ReducerParams } from 'helpers/types';
import { actionTypes as contractActionTypes } from 'ducks/contract';

export const actionTypes = {
  SELECT_REQUEST: 'PRODUCTS/SELECT_REQUEST',
  SELECT_SUCCESS: 'PRODUCTS/SELECT_SUCCES',
  SELECT_FAILURE: 'PRODUCTS/SELECT_FAILURE',
};

export type ProductsState = {
  isLoading: boolean;
  error?: string;
  selectedProducts: Object;
};

export const initialState = {
  isLoading: false,
  selectedProducts: {},
};

const products = (state: ProductsState = initialState, { type, payload }: ReducerParams): ProductsState => {
  switch (type) {
    case actionTypes.SELECT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.SELECT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: '',
        selectedProducts: payload,
      };
    }
    case actionTypes.SELECT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case contractActionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default products;

export const actions = {
  selectProductsRequest: (productGroups: any, data: any, callback: Function) => ({
    type: actionTypes.SELECT_REQUEST,
    productGroups,
    data,
    callback,
  }),
  selectProductsSuccess: (selectedProducts: Object) => ({
    type: actionTypes.SELECT_SUCCESS,
    payload: selectedProducts,
  }),
  selectProductsFailure: (error: ErrorType) => ({
    type: actionTypes.SELECT_FAILURE,
    payload: error,
  }),
};
