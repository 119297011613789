import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import isArray from 'lodash/isArray';

// Not sure if correct typing, don't be afraid to change it
export const arrayToKeyList = <T extends Record<string, any>>(
  arr: T[],
  key: keyof T = 'entityId',
  recc?: (item: T) => T,
) =>
  reduce(
    arr,
    (cur, next) => ({
      ...cur,
      [next[key]]: {
        ...next,
        ...(recc ? recc(next) : {}),
      },
    }),
    {},
  );

export const denormalizeInvestmentQuestionnaire = (questionnaire: any, answers: any) => {
  let denormalized = { ...questionnaire };
  questionnaire.questions.forEach((question: any, i: number) => {
    if (answers && answers[i]) {
      switch (question.type) {
        case 'SINGLE_CHOICE': {
          const selectedIndex = parseInt(answers[i], 10);
          question.options.forEach((option: any, j: number) => {
            denormalized.questions[i].options[j].selected = j === selectedIndex;
          });
          break;
        }
        case 'MULTIPLE_CHOICE': {
          question.options.forEach((option: any, j: number) => {
            denormalized.questions[i].options[j].selected = answers[i][j];
          });
          break;
        }
        case 'OPEN': {
          denormalized.questions[i].options[0].answer = answers[i];
          break;
        }
        default: {
          console.error('Unknown questionnaire question type.');
        }
      }
    }
  });
  return denormalized;
};

export const denormalizeSelectedProducts = (productGroups: any, selectedProducts: any) => {
  let denormalized: any[] = [];
  const allProducts = [].concat(...(Object.values(productGroups) as any)); // Flatten productGroups to products
  allProducts.forEach((product: any, i: number) => {
    if (selectedProducts[product.code.code]) {
      denormalized.push({ code: product.code.code, '@type': product.code['@type'] });
    }
  });
  return denormalized;
};

export const normalizeSelectedProducts = (products: any) => {
  const normalized: any = {};
  products.forEach((product: any) => (normalized[product] = true));
  return normalized;
};

export const denormalizeInvestment = (investment: any) => {
  return omit(investment, 'product.contractTypes');
};

export const mapValuesToPieSeries = (values: any) =>
  reduce(
    values,
    (next, value, key) => {
      next.push({
        name: key,
        y: value,
      });

      return next;
    },
    [] as any[],
  );

export const mapAssetsDataToChart = ({ data }: any) =>
  map(data, ({ title, coords }) => ({
    name: title,
    data: coords,
  }));

export const mapObjectToTableRows = (obj: any, keysDict: any, allowedAttrs: any[] = [], valsFormatters: any = {}) =>
  reduce(
    obj,
    (next, val, key) => {
      if (allowedAttrs && allowedAttrs.length && allowedAttrs.indexOf(key) === -1) {
        return next;
      } else if (!(allowedAttrs && allowedAttrs.length)) {
        return [
          ...next,
          {
            cells: [
              { cellContent: keysDict[key] },
              { cellContent: valsFormatters[key] ? valsFormatters[key](obj, val) : val },
            ],
          },
        ];
      }

      next[allowedAttrs.indexOf(key)] = {
        cells: [
          { cellContent: keysDict[key] },
          { cellContent: valsFormatters[key] ? valsFormatters[key](obj, val) : val },
        ],
      };

      return next;
    },
    allowedAttrs && allowedAttrs.length ? new Array(allowedAttrs.length) : [],
  );

export const mapObjectToChartData = (obj: any) =>
  reduce(
    obj,
    (next, data, name) => {
      next.push({
        name,
        data: isArray(data) ? data : [data],
      });
      return next;
    },
    [] as any,
  );

export const madObjectToColumnChartData = (obj: any) =>
  reduce(
    obj,
    (next, data, key) => {
      next.push([key, data]);
      return next;
    },
    [] as any,
  );

export const mapMsRatingsToTable = (obj: any) =>
  map(obj, (val, key) => ({ ...val, rating: key })).sort((x, y) => (x.rating <= y.rating) as never as number); // Probably bug and won't work

/**
 * Converts map of arrays to list of objects with array property.
 */
export const mapToList = (obj: any): Array<Object> | null => {
  if (!obj || obj.length) {
    return null;
  }

  return Object.keys(obj).map((k) => {
    return { [k]: obj[k] };
  });
};

export const clientInfoToClientHeaderData = (client: any) => {
  const [firstname, lastname] = client.name.split(' ');

  return {
    entityId: client.id,
    data: {
      contact: { email: client.email, phone: client.phone },
      firstname,
      lastname,
      type: 'PHYSICAL',
    },
  };
};

export const clientTargetsToOptions = (client: any) =>
  client.targets.map(({ id: value, name: label }: any) => ({ value, label }));
