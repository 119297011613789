import { handleRequest } from 'helpers/handleRequest';
import { actions } from 'ducks/investments';
import { getContractId } from 'ducks/contract';
import { actions as valuesActions, getFeeTypes, getCurrencies } from 'ducks/values';
import { call, put, select } from 'redux-saga/effects';
import { api } from 'constants/api';
import { denormalizeInvestment } from 'helpers/normalizer';

export function* get(action: any): any {
  try {
    // Preload feeTypes if not loaded yet
    const feeTypes = yield select(getFeeTypes);
    if (!feeTypes) {
      yield put(valuesActions.feeTypesRequest());
    }

    // Preload currencies if not loaded yet
    const currencies = yield select(getCurrencies);
    if (!currencies) {
      yield put(valuesActions.currenciesRequest());
    }

    // const frequencies = yield select(getFrequencies);
    // if (!frequencies) {
    // 	yield put(valuesActions.frequenciesRequest());
    // }

    const contractId = yield select(getContractId);
    const res = yield call(handleRequest, api.INVESTMENTS_GET, {
      query: {
        contract: contractId,
      },
    });
    yield put(actions.getSuccess(res));
  } catch (e: any) {
    yield put(actions.getFailure(e.message));
  }
}

export function* save(action: any): any {
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.INVESTMENTS_SAVE, {
      body: JSON.stringify(denormalizeInvestment(action.investment)),
      method: 'POST',
      query: {
        contract: contractId,
      },
    });
    yield put(actions.getRequest()); // Reload the whole investments object
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.saveFailure(e.message));
  }
}

export function* remove(action: any): any {
  try {
    const contractId = yield select(getContractId);
    yield call(handleRequest, api.INVESTMENTS_REMOVE, {
      body: JSON.stringify(denormalizeInvestment(action.investment)),
      method: 'DELETE',
      query: {
        contract: contractId,
        id: action.entityId,
      },
    });
    yield put(actions.getRequest()); // Reload the whole investments object
  } catch (e: any) {
    yield put(actions.removeFailure(e.message));
  }
}
