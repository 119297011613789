import { call, put, select } from 'redux-saga/effects';
import { handleRequest } from 'helpers/handleRequest';
import { actions, getQuestionnaireSection, getQuestionnaireId } from 'ducks/investmentQuestionnaire';
import { getContractId } from 'ducks/contract';
import { getClientCardId } from 'ducks/client';
import { api } from 'constants/api';
import { BranchIdTypeEnum, QuestionnaireAnswerProps } from 'helpers/types';

export function* evaluateCompleted({ questionnaireId }: { questionnaireId: string }): any {
  try {
    const contractId = yield select(getContractId);
    const result = yield call(handleRequest, api.INVESTMENT_QUESTIONNAIRE_EVALUATE, {
      method: 'POST',
      query: {
        questionnaireId,
        contractId,
      },
    });
    yield put(actions.evaluateCompletedSuccess(result));
  } catch (e: any) {
    yield put(actions.evaluateCompletedFailure(e));
  }
}

/**
 * Create new questionnaire
 * @param {Object}
 * 	@param {Object} questionnaireType - type of questionnaire
 */
export function* create({ questionnaireType }: { questionnaireType: BranchIdTypeEnum }): any {
  try {
    const clientCardId = yield select(getClientCardId);
    const result = yield call(handleRequest, api.INVESTMENT_QUESTIONNAIRE_CREATE(questionnaireType), {
      query: {
        clientCardId,
      },
    });
    yield put(actions.createSuccess(result.questionnaireId));
    yield put(actions.getQuestionDataRequest(questionnaireType));
  } catch (e: any) {
    yield put(actions.createFailure(e));
  }
}

/**
 * @param {Object}
 * 	@param questionnaireType - type of questionnaire
 * 	@param section - step of questionnaire to get data for
 *  @param questionnaireId
 */
type GetQuestionDataProps = {
  questionnaireType: BranchIdTypeEnum;
  section?: number;
  prev?: boolean;
};
export function* getQuestionData(action: GetQuestionDataProps): any {
  try {
    const storedSection = yield select(getQuestionnaireSection);
    const questionnaireId = yield select(getQuestionnaireId);
    const section = action.section || storedSection;
    const isNext = !action.prev;

    // console.info(`%c  calling getQuestionData, section: ${section}`, 'color: green')

    const result = yield call(handleRequest, api.INVESTMENT_QUESTIONNAIRE_GET(action.questionnaireType), {
      query: {
        section,
        questionnaireId,
        isNext,
      },
    });
    yield put(actions.getQuestionDataSuccess(result));
  } catch (e: any) {
    yield put(actions.getQuestionDataFailure(e));
  } finally {
    yield put(actions.stopLoading());
  }
}

export type SendAnswersProps = {
  questionnaireType: BranchIdTypeEnum;
  answers: QuestionnaireAnswerProps;
  isLast: boolean;
};
export function* sendAnswers({ questionnaireType, answers, isLast }: SendAnswersProps): any {
  try {
    const questionnaireId = yield select(getQuestionnaireId);
    const section = yield select(getQuestionnaireSection);
    const body = JSON.stringify({ answers });
    // console.info(`%c  calling sendAnswers`, 'color: yellow')

    const result = yield call(handleRequest, api.INVESTMENT_QUESTIONNAIRE_EDIT(questionnaireType), {
      method: 'POST',
      query: {
        section,
        questionnaireId,
      },
      body: body,
    });

    yield put(actions.sendAnswersSuccess(result));
    // evaluate questionnaire
    if (isLast === true) {
      yield put(actions.evaluateRequest());
      // call data for next question
    } else {
      yield put(actions.getQuestionDataRequest(questionnaireType, section + 1));
    }
  } catch (e: any) {
    yield put(actions.sendAnswersFailure(e));
  }
}

type EvaluateProps = {
  questionnaireId: string;
};
export function* evaluate({ questionnaireId }: EvaluateProps): any {
  try {
    const contractId = yield select(getContractId);
    const storedQuestionnaireId = yield select(getQuestionnaireId);
    const url = api.INVESTMENT_QUESTIONNAIRE_EVALUATE;
    const result = yield call(handleRequest, url, {
      method: 'POST',
      query: {
        questionnaireId: questionnaireId || storedQuestionnaireId,
        contractId,
      },
    });
    yield put(actions.evaluateSuccess(result));
  } catch (e: any) {
    yield put(actions.evaluateFailure(e));
  }
}

export function* emptySend(action: any): any {
  try {
    const contractId = yield select(getContractId);
    const result = yield call(handleRequest, api.INVESTMENT_QUESTIONNAIRE_EMPTY_SEND, {
      query: {
        contract: contractId,
      },
    });
    yield put(actions.emptySendSuccess(result.timestamp));
  } catch (e: any) {
    yield put(actions.emptySendFailure(e));
  }
}

export function* emptyPrint(action: any): any {
  try {
    const contractId = yield select(getContractId);

    yield call(handleRequest, api.INVESTMENT_QUESTIONNAIRE_EMPTY_PRINT_DATA, {
      query: {
        contract: contractId,
      },
    });

    const result = yield call(handleRequest, api.INVESTMENT_QUESTIONNAIRE_EMPTY_PRINT, {
      query: {
        contract: contractId,
      },
    });
    yield put(actions.emptyPrintSuccess(result.timestamp));
  } catch (e: any) {
    yield put(actions.emptyPrintFailure(e));
  }
}

export function* evaluationSend(action: any): any {
  try {
    const contractId = yield select(getContractId);
    const result = yield call(handleRequest, api.INVESTMENT_QUESTIONNAIRE_EVALUATION_SEND, {
      query: {
        contract: contractId,
      },
    });
    yield put(actions.evaluationSendSuccess(result.timestamp));
  } catch (e: any) {
    yield put(actions.evaluationSendFailure(e));
  } finally {
    yield put(actions.stopLoading());
  }
}

export function* evaluationPrint(action: any): any {
  try {
    const contractId = yield select(getContractId);

    yield call(handleRequest, api.INVESTMENT_QUESTIONNAIRE_EVALUATION_PRINT_DATA, {
      query: {
        contract: contractId,
      },
    });

    const result = yield call(handleRequest, api.INVESTMENT_QUESTIONNAIRE_EVALUATION_PRINT, {
      query: {
        contract: contractId,
      },
    });
    yield put(actions.evaluationPrintSuccess(result.timestamp));
  } catch (e: any) {
    yield put(actions.evaluationPrintFailure(e));
  }
}
