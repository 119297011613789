import { ReducerParams, ReducerState, ErrorType } from 'helpers/types';

export const actionTypes = {
  CREATE_REQUEST: 'CORE_DATA/CREATE_REQUEST',
  CREATE_SUCCESS: 'CORE_DATA/CREATE_SUCCES',
  CREATE_FAILURE: 'CORE_DATA/CREATE_FAILURE',
};

export interface InvestmentQuestionnaireState extends ReducerState {
  questionnaireId: string | null | undefined;
}

export const initialState = {
  questionnaireId: null,
  isLoading: false,
  error: null,
};

const investmentQuestionnaire = (
  state: InvestmentQuestionnaireState = initialState,
  { type, payload }: ReducerParams,
): InvestmentQuestionnaireState => {
  switch (type) {
    case actionTypes.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        questionnaire: payload, // Probably error here, but for compatibility reasons
        questionnaireId: payload,
      } as InvestmentQuestionnaireState;
    case actionTypes.CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default investmentQuestionnaire;

export const actions = {
  createRequest: (link: string) => ({
    type: actionTypes.CREATE_REQUEST,
    payload: {
      link,
    },
  }),
  createSuccess: (questionnaireId: string) => ({
    type: actionTypes.CREATE_SUCCESS,
    payload: questionnaireId,
  }),
  createFailure: (error: ErrorType) => ({
    type: actionTypes.CREATE_FAILURE,
    payload: error,
  }),
};

//export const getQuestionnaireId = (state: MicrositeState): string =>
//	state.invesmentQuestionnaire && state.invesmentQuestionnaire.questionnaireId;
