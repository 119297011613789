import { ReducerParams, ReducerState, ErrorType, UserInfo } from 'helpers/types';

export const actionTypes = {
  GET_REQUEST: 'PROFILE/GET_REQUEST',
  GET_SUCCESS: 'PROFILE/GET_SUCCES',
  GET_FAILURE: 'PROFILE/GET_FAILURE',
  SWITCH_PARTNER_REQUEST: 'PROFILE/SWITCH_PARTNER_REQUEST',
  RESET: 'PROFILE/RESET',
};

export interface ProfileState extends ReducerState {
  model: UserInfo;
}

export const initialState = {
  isLoading: false,
  model: {},
  error: null,
};

const profile = (state: ProfileState = initialState, { type, payload }: ReducerParams): ProfileState => {
  switch (type) {
    case actionTypes.GET_REQUEST:
    case actionTypes.SWITCH_PARTNER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        model: {
          ...state.model,
          ...payload,
        },
      };
    case actionTypes.GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default profile;

export const actions = {
  getRequest: () => ({
    type: actionTypes.GET_REQUEST,
  }),
  getSuccess: (profile: UserInfo) => ({
    type: actionTypes.GET_SUCCESS,
    payload: profile,
  }),
  getFailure: (error: ErrorType) => ({
    type: actionTypes.GET_FAILURE,
    payload: error,
  }),
  switchPartnerRequest: (partnerCode: String) => ({
    type: actionTypes.SWITCH_PARTNER_REQUEST,
    payload: partnerCode,
  }),
  reset: () => ({
    type: actionTypes.RESET,
  }),
};

export const getPartnerId = (state: any) => state.profile.model.partner.partnerCode;
export const getPermissions = (state: any) => state.profile.model.permissions;
export const getCommissionsToken = (state: any) => state.profile.model.btToken;
