import './SignPost.css';
import React from 'react';
import compact from 'lodash/compact';

type SignPostProps = {
  children: any;
  animation?: boolean;
  className?: string;
};

const SignPostItem: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <li className="SignPost__item">{children}</li>
);

export default class SignPost extends React.PureComponent<SignPostProps> {
  render() {
    const { children, className = null } = this.props;
    const className_ = compact(['SignPost', className]);
    const children_ = compact([].concat(children));

    return (
      <ul className={className_.join(' ')} data-noanimation={this.props.animation === false || null}>
        {children_.map((child: any, i: number) => (
          <SignPostItem key={i}>{child}</SignPostItem>
        ))}
      </ul>
    );
  }
}
