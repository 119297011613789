import { handleRequest } from 'helpers/handleRequest';
import { call, put, select } from 'redux-saga/effects';
import { getContractId } from 'ducks/contract';
import { actions, getClientId } from 'ducks/client';
import { api } from 'constants/api';
import { clientTypes } from 'constants/constants';
import { scrollToTop } from 'helpers/helpers';

// Load by personId
export function* loadClient(action: any): any {
  try {
    const res = yield call(handleRequest, api.CLIENT_LOAD, {
      query: {
        personId: action.payload,
      },
    });
    yield put(actions.getSuccess(res));
  } catch (e: any) {
    yield put(actions.getFailure(e));
  }
}

// reLoad by clientId
export function* reloadClient(action: any): any {
  try {
    const clientId = yield select(getClientId);
    const res = yield call(handleRequest, api.CLIENT_GET, {
      query: {
        id: clientId,
      },
    });
    yield put(actions.getSuccess(res));
  } catch (e: any) {
    yield put(actions.getFailure(e));
  }
}

export function* saveClient(action: any): any {
  try {
    const contractId = yield select(getContractId);
    const savedClient = yield call(handleRequest, api.CLIENT_SAVE, {
      query: {
        strict: action.strictValidation,
        contract: contractId,
      },
      body: JSON.stringify(action.payload),
      method: 'POST',
    });
    yield put(actions.saveSuccess(savedClient));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.saveFailure(e));
    scrollToTop();
  }
}

export function* newClient(action: any): any {
  try {
    const res = yield call(
      handleRequest,
      action.payload === clientTypes.PHYSICAL ? api.CLIENT_NEW_PHYSICAL : api.CLIENT_NEW_LEGAL,
    );
    yield put(actions.newSuccess(res));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e: any) {
    yield put(actions.newFailure(e));
  }
}

export function* checkPersonAgreement(action: any): any {
  try {
    yield call(handleRequest, (api as any).PERSON_AGREEMENT_CHECK, {
      // Probably error, if used
      query: {
        id: action.payload.personId,
      },
    });
    yield put(actions.reloadRequest());
  } catch (e: any) {
    yield put(actions.checkPersonAgreementFailure(e));
  }
}

export function* sendPersonAgreement(action: any): any {
  try {
    yield call(handleRequest, api.PERSON_AGREEMENT_SEND, {
      query: {
        id: action.payload.personId,
      },
    });
    if (action.callback) {
      yield call(action.callback);
    }
    yield put(actions.reloadRequest());
  } catch (e: any) {
    yield put(actions.sendPersonAgreementFailure(e));
  }
}

export function* scanPersonAgreement(action: any): any {
  try {
    yield call(handleRequest, api.PERSON_AGREEMENT_SCAN, {
      body: action.payload.formData,
      method: 'POST',
      setContentType: false,
      query: {
        id: action.payload.personId,
      },
    });
    if (action.callback) {
      yield call(action.callback);
    }
    yield put(actions.reloadRequest());
  } catch (e: any) {
    yield put(actions.scanPersonAgreementFailure(e));
  }
}
