import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import './App.css';

import { UserPermissionsProps } from 'helpers/types';

import AppContent from 'components/AppContent/AppContent';
import AppFooter from 'components/AppFooter/AppFooter';
import AppHeader from 'components/AppHeader/AppHeader';
import LoadingComponent from 'components/LoadingComponent';
import PageNotFound from 'containers/common/PageNotFound/PageNotFound';
import PrivateRoute from 'components/PrivateRoute';
import { State } from 'ducks';

const AsyncAdmin = Loadable({
  loader: () => import('containers/admin/Index'),
  loading: LoadingComponent,
});

const AsyncCallback = Loadable({
  loader: () => import('containers/Callback'),
  loading: LoadingComponent,
});

const AsyncHomepage = Loadable({
  loader: () => import('containers/homepage'),
  loading: LoadingComponent,
});

const AsyncContract = Loadable({
  loader: () => import('containers/contract/Index'),
  loading: LoadingComponent,
});

const AsyncMaterials = Loadable({
  loader: () => import('containers/materials/Index'),
  loading: LoadingComponent,
});

const AsyncReporting = Loadable({
  loader: () => import('containers/reporting/Index'),
  loading: LoadingComponent,
});

type AppProps = {
  user: any;
  permissions: UserPermissionsProps;
};

class App extends React.Component<AppProps> {
  render() {
    const { user, permissions } = this.props;
    const routeProps = { user, permissions };
    // TODO: create manager to handle props
    return (
      <Router>
        <div className="App">
          <AppHeader user={user} />
          <AppContent>
            <Switch>
              <Route path="/callback" component={AsyncCallback} exact />
              <PrivateRoute path="/" component={AsyncHomepage} {...routeProps} exact />
              <PrivateRoute
                path="/contract"
                component={AsyncContract as any}
                {...routeProps}
                // @ts-ignore
                access={'generalFlow'}
              />
              <PrivateRoute path="/materials" component={AsyncMaterials as any} {...routeProps} />
              <PrivateRoute path="/reporting" component={AsyncReporting} {...routeProps} />
              <PrivateRoute path="/admin" component={AsyncAdmin as any} {...routeProps} access="ipAdmin" />
              <PrivateRoute component={PageNotFound} {...routeProps} />
            </Switch>
          </AppContent>
          <AppFooter user={user} />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state: State) => ({
  user: state.oidc!.user,
  permissions: state.profile.model.permissions,
});

// @ts-ignore
export default connect(mapStateToProps)(App);
