import { handleRequest } from 'helpers/handleRequest';
import { actions } from 'ducks/investmentIntermediaryInfo';
import { getContractId } from 'ducks/contract';
import { call, put, select } from 'redux-saga/effects';
import { api } from 'constants/api';

export function* send(action: any): any {
  try {
    const contractId = yield select(getContractId);
    const result = yield call(handleRequest, api.INFO_SEND, {
      query: {
        contract: contractId,
      },
    });
    yield put(actions.sendSuccess(result.timestamp));
    action.callback && action.callback();
  } catch (e: any) {
    yield put(actions.sendFailure(e.message));
  }
}

export function* print(action: any): any {
  try {
    const contractId = yield select(getContractId);

    yield call(handleRequest, api.INFO_PRINT_DATA, {
      query: {
        contract: contractId,
      },
    });

    const result = yield call(handleRequest, api.INFO_PRINT, {
      query: {
        contract: contractId,
      },
    });
    yield put(actions.printSuccess(result.timestamp));
  } catch (e: any) {
    yield put(actions.printFailure(e));
  }
}
