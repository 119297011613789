import React, { useState, useEffect } from 'react';

import Modal from 'components/Modal/Modal';
import SignPost from 'components/SignPost/SignPost';
import { Tile, SelectedTileContent } from './AppHeaderHelpModal.Tile';
import { Help2Table } from './AppHeaderHelpModalContent';
import TILES_DATA from './data/tilesData.json';

type AppHeaderHelpModalProps = {
  toggleHelpModal: (e?: React.MouseEvent<HTMLElement>) => void;
};

const AppHeaderHelpModal = (props: AppHeaderHelpModalProps) => {
  const [helpId, setHelpId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<string | number>(1);
  const handleSetHelpId = (id: number) => {
    setHelpId(id);
    setActiveTab(1);
  };
  const selectedSection = helpId && !isNaN(helpId) ? TILES_DATA[helpId - 1] : null;
  const selectedTabTitle = selectedSection && selectedSection.tabs ? selectedSection.title : null;

  useEffect(() => {
    return () => setHelpId(null);
  }, []);

  return (
    <Modal
      onClose={props.toggleHelpModal}
      title={`${
        selectedTabTitle ||
        (selectedSection ? `${selectedSection.video ? 'Videonápověda:' : ''} ${selectedSection.title}` : 'Nápověda')
      }`}
      noMaxWidth={true}
    >
      {helpId === null && (
        <React.Fragment>
          <SignPost className="SECTION_SELECTION">
            <Tile {...TILES_DATA[0]} handleOnClick={handleSetHelpId} />
            <Tile {...TILES_DATA[1]} handleOnClick={handleSetHelpId} />
          </SignPost>
          <SignPost className="SECTION_SELECTION">
            <Tile {...TILES_DATA[2]} handleOnClick={handleSetHelpId} />
            <Tile {...TILES_DATA[3]} />
          </SignPost>
        </React.Fragment>
      )}
      {selectedSection !== null && (
        <SelectedTileContent
          {...selectedSection}
          activeTab={activeTab}
          handleSetTab={setActiveTab}
          handleClose={() => {
            setHelpId(null);
            setActiveTab(1);
          }}
        >
          {selectedSection.id === 2 && activeTab === 4 ? <Help2Table /> : null}
        </SelectedTileContent>
      )}
    </Modal>
  );
};

export default AppHeaderHelpModal;
