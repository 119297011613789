import { handleRequest } from 'helpers/handleRequest';
import { call, put } from 'redux-saga/effects';
import { actions } from 'ducks/microsite/investmentQuestionnaire';
import { api } from 'constants/api';
import { paramsToUrlMapper } from 'helpers/helpers';

export function* create(action: { payload: { link: string } }): any {
  try {
    const data = yield call(
      handleRequest,
      paramsToUrlMapper(api.MICROSITE.INVESTMENT_QUESTIONNAIRE_NEW, action.payload),
      {
        auth: false,
      },
    );
    console.log(data);
    yield put(actions.createSuccess(data));
  } catch (e: any) {
    yield put(actions.createFailure(e));
  }
}
