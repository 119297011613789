import { ReducerParams, ErrorType } from 'helpers/types';
import { actionTypes as contractActionTypes } from 'ducks/contract';

export const actionTypes = {
  CLIENT_PRINT_REQUEST: 'DOCUMENTS/CLIENT_PRINT_REQUEST',
  CLIENT_PRINT_SUCCESS: 'DOCUMENTS/CLIENT_PRINT_SUCCES',
  CLIENT_PRINT_FAILURE: 'DOCUMENTS/CLIENT_PRINT_FAILURE',
  CLIENT_PRINT_DATA_REQUEST: 'DOCUMENTS/CLIENT_PRINT_DATA_REQUEST',
  CLIENT_PRINT_DATA_SUCCESS: 'DOCUMENTS/CLIENT_PRINT_DATA_SUCCES',

  CLIENT_SEND_REQUEST: 'DOCUMENTS/CLIENT_SEND_REQUEST',
  CLIENT_SEND_SUCCESS: 'DOCUMENTS/CLIENT_SEND_SUCCES',
  CLIENT_SEND_FAILURE: 'DOCUMENTS/CLIENT_SEND_FAILURE',

  INTERNAL_PRINT_REQUEST: 'DOCUMENTS/INTERNAL_PRINT_REQUEST',
  INTERNAL_PRINT_SUCCESS: 'DOCUMENTS/INTERNAL_PRINT_SUCCES',
  INTERNAL_PRINT_FAILURE: 'DOCUMENTS/INTERNAL_PRINT_FAILURE',
  INTERNAL_PRINT_DATA_REQUEST: 'DOCUMENTS/INTERNAL_PRINT_DATA_REQUEST',
  INTERNAL_PRINT_DATA_SUCCESS: 'DOCUMENTS/INTERNAL_PRINT_DATA_SUCCES',

  PRINT_ALL_REQUEST: 'DOCUMENTS/PRINT_ALL_REQUEST',
  PRINT_ALL_SUCCESS: 'DOCUMENTS/PRINT_ALL_SUCCESS',
  PRINT_ALL_FAILURE: 'DOCUMENTS/PRINT_ALL_FAILURE',
};

export type DocumentsState = {
  isLoading: boolean;
  error: ErrorType | null;
  clientPrintDateTime: string;
  clientSendDateTime: string;
  internalPrintDateTime: string;
};

export const initialState = {
  isLoading: false,
  clientPrintDateTime: '',
  clientSendDateTime: '',
  internalPrintDateTime: '',
  error: null,
};

const documents = (state: DocumentsState = initialState, { type, payload }: ReducerParams): DocumentsState => {
  switch (type) {
    case actionTypes.CLIENT_PRINT_REQUEST:
    case actionTypes.CLIENT_PRINT_DATA_REQUEST:
    case actionTypes.CLIENT_SEND_REQUEST:
    case actionTypes.INTERNAL_PRINT_REQUEST:
    case actionTypes.INTERNAL_PRINT_DATA_REQUEST:
    case actionTypes.PRINT_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.CLIENT_PRINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        clientPrintDateTime: payload,
      };

    case actionTypes.CLIENT_SEND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        clientSendDateTime: payload,
      };

    case actionTypes.INTERNAL_PRINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        internalPrintDateTime: payload,
      };
    case actionTypes.PRINT_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case actionTypes.CLIENT_PRINT_FAILURE:
    case actionTypes.CLIENT_SEND_FAILURE:
    case actionTypes.INTERNAL_PRINT_FAILURE:
    case actionTypes.PRINT_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case contractActionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default documents;

export const actions = {
  clientPrintDataRequest: (callback: Function) => ({
    type: actionTypes.CLIENT_PRINT_DATA_REQUEST,
    callback,
  }),
  clientPrintRequest: () => ({
    type: actionTypes.CLIENT_PRINT_REQUEST,
  }),
  clientPrintSuccess: (timestamp: string) => ({
    type: actionTypes.CLIENT_PRINT_SUCCESS,
    payload: timestamp,
  }),
  clientPrintFailure: (message?: string) => ({
    type: actionTypes.CLIENT_PRINT_FAILURE,
    payload: message,
  }),

  clientSendRequest: (callback: Function) => ({
    type: actionTypes.CLIENT_SEND_REQUEST,
    callback,
  }),
  clientSendSuccess: (timestamp: string) => ({
    type: actionTypes.CLIENT_SEND_SUCCESS,
    payload: timestamp,
  }),
  clientSendFailure: (message?: string) => ({
    type: actionTypes.CLIENT_SEND_FAILURE,
    payload: message,
  }),

  internalPrintDataRequest: (callback: Function) => ({
    type: actionTypes.INTERNAL_PRINT_DATA_REQUEST,
    callback,
  }),
  internalPrintRequest: () => ({
    type: actionTypes.INTERNAL_PRINT_REQUEST,
  }),
  internalPrintSuccess: (timestamp: string) => ({
    type: actionTypes.INTERNAL_PRINT_SUCCESS,
    payload: timestamp,
  }),
  internalPrintFailure: (message?: string) => ({
    type: actionTypes.INTERNAL_PRINT_FAILURE,
    payload: message,
  }),
  printAllRequest: (callback: Function) => ({
    type: actionTypes.PRINT_ALL_REQUEST,
    callback,
  }),
  printAllSuccess: () => ({
    type: actionTypes.PRINT_ALL_SUCCESS,
  }),
  printAllFailure: (message?: string) => ({
    type: actionTypes.PRINT_ALL_FAILURE,
    payload: message,
  }),
};
