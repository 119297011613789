import React from 'react';

import './Message.css';

export enum MessageSeverity {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

type MessageProps = {
  /** Changes message style. Possible values are: 'error' | 'info' | 'warning'. Info style is the default one. */
  type: 'error' | 'warning' | 'info';
  /** If presents, shows the message "Bohužel, došlo k neočekávané chybě: <strong>{error}</strong>" */
  error?: string | null;
  /** Text of the message */
  message?: string;
  /** Custom children elements */
  children?: React.ReactNode;
  className?: string;
};

/**
 * Shows an info, error or warning message
 */
const Message = (props: MessageProps) => (
  <div className={`Message Message--${props.type}${props.className ? ' ' + props.className : ''}`}>
    {props.children ? (
      props.children
    ) : !props.message && props.error ? (
      <p>
        Bohužel, došlo k neočekávané chybě ({new Date().toLocaleString()}): <strong>{props.error}</strong>
      </p>
    ) : (
      <p>{props.message}</p>
    )}
  </div>
);

Message.defaultProps = {
  type: 'info',
};

export default Message;
