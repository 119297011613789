import { ReducerParams, ErrorType, ReducerState } from 'helpers/types';

export const actionTypes = {
  COUNTRIES_REQUEST: 'VALUES/COUNTRIES_REQUEST',
  COUNTRIES_SUCCESS: 'VALUES/COUNTRIES_SUCCES',
  COUNTRIES_FAILURE: 'VALUES/COUNTRIES_FAILURE',
  FEE_TYPES_REQUEST: 'VALUES/FEE_TYPES_REQUEST',
  FEE_TYPES_SUCCESS: 'VALUES/FEE_TYPES_SUCCES',
  FEE_TYPES_FAILURE: 'VALUES/FEE_TYPES_FAILURE',
  CURRENCIES_REQUEST: 'VALUES/CURRENCIES_REQUEST',
  CURRENCIES_SUCCESS: 'VALUES/CURRENCIES_SUCCES',
  CURRENCIES_FAILURE: 'VALUES/CURRENCIES_FAILURE',
  INDEXES_REQUEST: 'VALUES/INDEXES_REQUEST',
  INDEXES_SUCCESS: 'VALUES/INDEXES_SUCCES',
  INDEXES_FAILURE: 'VALUES/INDEXES_FAILURE',
  PRODUCERS_REQUEST: 'VALUES/PRODUCERS_REQUEST',
  PRODUCERS_SUCCESS: 'VALUES/PRODUCERS_SUCCES',
  PRODUCERS_FAILURE: 'VALUES/PRODUCERS_FAILURE',
  FREQUENCIES_REQUEST: 'VALUES/FREQUENCIES_REQUEST',
  FREQUENCIES_SUCCESS: 'VALUES/FREQUENCIES_SUCCES',
  FREQUENCIES_FAILURE: 'VALUES/FREQUENCIES_FAILURE',
};

interface ValuesItemState<T = any> extends Partial<ReducerState> {
  values?: T;
}

export interface ValuesState {
  countries: ValuesItemState;
  currencies: ValuesItemState;
  feeTypes: ValuesItemState;
  indexes: ValuesItemState;
  producers: ValuesItemState;
  frequencies: ValuesItemState;
}

export const initialState = {
  countries: {},
  currencies: {},
  feeTypes: {},
  indexes: {},
  producers: {},
  frequencies: {},
};

const values = (state: ValuesState = initialState, { type, payload }: ReducerParams) => {
  switch (type) {
    case actionTypes.COUNTRIES_REQUEST:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: true,
          error: '',
        },
      };
    case actionTypes.COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          isLoading: false,
          error: '',
          values: payload,
        },
      };
    case actionTypes.COUNTRIES_FAILURE:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: false,
          error: payload,
        },
      };
    case actionTypes.FEE_TYPES_REQUEST:
      return {
        ...state,
        feeTypes: {
          ...state.feeTypes,
          isLoading: true,
          error: '',
        },
      };
    case actionTypes.FEE_TYPES_SUCCESS:
      return {
        ...state,
        feeTypes: {
          isLoading: false,
          error: '',
          values: payload,
        },
      };
    case actionTypes.FEE_TYPES_FAILURE:
      return {
        ...state,
        feeTypes: {
          ...state.feeTypes,
          isLoading: false,
          error: payload,
        },
      };
    case actionTypes.CURRENCIES_REQUEST:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          isLoading: true,
          error: '',
        },
      };
    case actionTypes.CURRENCIES_SUCCESS: {
      return {
        ...state,
        currencies: {
          isLoading: false,
          error: '',
          values: payload,
        },
      };
    }
    case actionTypes.CURRENCIES_FAILURE:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          isLoading: false,
          error: payload,
        },
      };
    case actionTypes.INDEXES_REQUEST:
      return {
        ...state,
        indexes: {
          ...state.indexes,
          isLoading: true,
          error: '',
        },
      };
    case actionTypes.INDEXES_SUCCESS: {
      return {
        ...state,
        indexes: {
          isLoading: false,
          error: '',
          values: payload,
        },
      };
    }
    case actionTypes.INDEXES_FAILURE:
      return {
        ...state,
        indexes: {
          ...state.indexes,
          isLoading: false,
          error: payload,
        },
      };
    case actionTypes.PRODUCERS_REQUEST:
      return {
        ...state,
        producers: {
          ...state.producers,
          isLoading: true,
          error: null,
        },
      };
    case actionTypes.PRODUCERS_SUCCESS: {
      return {
        ...state,
        producers: {
          isLoading: false,
          error: null,
          values: payload,
        },
      };
    }
    case actionTypes.PRODUCERS_FAILURE:
      return {
        ...state,
        producers: {
          ...state.producers,
          isLoading: false,
          error: payload,
        },
      };
    case actionTypes.FREQUENCIES_REQUEST:
      return {
        ...state,
        frequencies: {
          ...state.frequencies,
          isLoading: true,
          error: null,
        },
      };
    case actionTypes.FREQUENCIES_SUCCESS:
      return {
        ...state,
        frequencies: {
          ...state.frequencies,
          isLoading: false,
          values: payload,
        },
      };
    case actionTypes.FREQUENCIES_FAILURE:
      return {
        ...state,
        frequencies: {
          ...state.frequencies,
          isLoading: false,
          error: payload,
        },
      };
    default:
      return state;
  }
};

export default values;

export const actions = {
  countriesRequest: () => ({
    type: actionTypes.COUNTRIES_REQUEST,
  }),
  countriesSuccess: (countries: Array<any>) => ({
    type: actionTypes.COUNTRIES_SUCCESS,
    payload: countries,
  }),
  countriesFailure: (message?: string) => ({
    type: actionTypes.COUNTRIES_FAILURE,
    payload: message,
  }),
  feeTypesRequest: () => ({
    type: actionTypes.FEE_TYPES_REQUEST,
  }),
  feeTypesSuccess: (feeTypes: Array<any>) => ({
    type: actionTypes.FEE_TYPES_SUCCESS,
    payload: feeTypes,
  }),
  feeTypesFailure: (message?: string) => ({
    type: actionTypes.FEE_TYPES_FAILURE,
    payload: message,
  }),
  currenciesRequest: (auth: boolean = true) => ({
    type: actionTypes.CURRENCIES_REQUEST,
    auth,
  }),
  currenciesSuccess: (currencies: Array<string>) => ({
    type: actionTypes.CURRENCIES_SUCCESS,
    payload: currencies,
  }),
  currenciesFailure: (message?: string) => ({
    type: actionTypes.CURRENCIES_FAILURE,
    payload: message,
  }),
  indexesRequest: () => ({
    type: actionTypes.INDEXES_REQUEST,
  }),
  indexesSuccess: (indexes: Array<any>) => ({
    type: actionTypes.INDEXES_SUCCESS,
    payload: indexes,
  }),
  indexesFailure: (message?: string) => ({
    type: actionTypes.INDEXES_FAILURE,
    payload: message,
  }),
  producersRequest: () => ({
    type: actionTypes.PRODUCERS_REQUEST,
  }),
  producersSuccess: (producers: Object) => ({
    type: actionTypes.PRODUCERS_SUCCESS,
    payload: producers,
  }),
  producersFailure: (message: ErrorType) => ({
    type: actionTypes.PRODUCERS_FAILURE,
    payload: message,
  }),
  frequenciesRequest: (contract: any) => ({
    type: actionTypes.FREQUENCIES_REQUEST,
    payload: contract,
  }),
  frequenciesSuccess: (frequencies: Object) => ({
    type: actionTypes.FREQUENCIES_SUCCESS,
    payload: frequencies,
  }),
  frequenciesFailure: (message: ErrorType) => ({
    type: actionTypes.FREQUENCIES_FAILURE,
    payload: message,
  }),
};

// selectors

export const getCountries = (state: any) => state.values.countries.values;
export const getFeeTypes = (state: any) => state.values.feeTypes.values;
export const getCurrencies = (state: any) => state.values.currencies.values;
export const getIndexes = (state: any) => state.values.indexes.values;
export const getProducers = (state: any) => state.values.producers;
export const getFrequencies = (state: any) => state.values.frequencies.values;
