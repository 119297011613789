import {
  ReducerParams,
  ReducerState,
  ErrorType,
  InvestmentQuestionnaireResultType,
  PrintSend,
  BranchIdTypeEnum,
  QuestionnaireProps,
  QuestionnaireAnswerProps,
} from 'helpers/types';
import { actionTypes as contractActionTypes } from 'ducks/contract';

export const actionTypes = {
  STOP_LOADING: 'INVESTMENT_QUESTIONNAIRE/STOP_LOADING',

  CREATE_REQUEST: 'INVESTMENT_QUESTIONNAIRE/CREATE_REQUEST',
  CREATE_SUCCESS: 'INVESTMENT_QUESTIONNAIRE/CREATE_SUCCESS',
  CREATE_FAILURE: 'INVESTMENT_QUESTIONNAIRE/CREATE_FAILURE',

  GET_QUESTION_REQUEST: 'INVESTMENT_QUESTIONNAIRE/GET_QUESTION_REQUEST',
  GET_QUESTION_SUCCESS: 'INVESTMENT_QUESTIONNAIRE/GET_QUESTION_SUCCESS',
  GET_QUESTION_FAILURE: 'INVESTMENT_QUESTIONNAIRE/GET_QUESTION_FAILURE',

  SEND_ANSWERS_REQUEST: 'INVESTMENT_QUESTIONNAIRE/SEND_ANSWERS_REQUEST',
  SEND_ANSWERS_SUCCESS: 'INVESTMENT_QUESTIONNAIRE/SEND_ANSWERS_SUCCESS',
  SEND_ANSWERS_FAILURE: 'INVESTMENT_QUESTIONNAIRE/SEND_ANSWERS_FAILURE',

  EVALUATE_COMPLETED_REQUEST: 'INVESTMENT_QUESTIONNAIRE/EVALUATE_COMPLETED_REQUEST',
  EVALUATE_COMPLETED_SUCCESS: 'INVESTMENT_QUESTIONNAIRE/EVALUATE_COMPLETED_SUCCESS',
  EVALUATE_COMPLETED_FAILURE: 'INVESTMENT_QUESTIONNAIRE/EVALUATE_COMPLETED_FAILURE',

  EVALUATE_REQUEST: 'INVESTMENT_QUESTIONNAIRE/EVALUATE_REQUEST',
  EVALUATE_SUCCESS: 'INVESTMENT_QUESTIONNAIRE/EVALUATE_SUCCES',
  EVALUATE_FAILURE: 'INVESTMENT_QUESTIONNAIRE/EVALUATE_FAILURE',

  EVALUATION_SEND_REQUEST: 'INVESTMENT_QUESTIONNAIRE/EVALUATION_SEND_REQUEST',
  EVALUATION_SEND_SUCCESS: 'INVESTMENT_QUESTIONNAIRE/EVALUATION_SEND_SUCCES',
  EVALUATION_SEND_FAILURE: 'INVESTMENT_QUESTIONNAIRE/EVALUATION_SEND_FAILURE',
  EVALUATION_PRINT_REQUEST: 'INVESTMENT_QUESTIONNAIRE/EVALUATION_PRINT_REQUEST',
  EVALUATION_PRINT_SUCCESS: 'INVESTMENT_QUESTIONNAIRE/EVALUATION_PRINT_SUCCES',
  EVALUATION_PRINT_FAILURE: 'INVESTMENT_QUESTIONNAIRE/EVALUATION_PRINT_FAILURE',
  EVALUATION_PRINT_DATA_REQUEST: 'INVESTMENT_QUESTIONNAIRE/EVALUATION_PRINT_DATA_REQUEST',
  EVALUATION_PRINT_DATA_SUCCESS: 'INVESTMENT_QUESTIONNAIRE/EVALUATION_PRINT_DATA_SUCCES',
  EMPTY_SEND_REQUEST: 'INVESTMENT_QUESTIONNAIRE/EMPTY_SEND_REQUEST',
  EMPTY_SEND_SUCCESS: 'INVESTMENT_QUESTIONNAIRE/EMPTY_SEND_SUCCES',
  EMPTY_SEND_FAILURE: 'INVESTMENT_QUESTIONNAIRE/EMPTY_SEND_FAILURE',
  EMPTY_PRINT_REQUEST: 'INVESTMENT_QUESTIONNAIRE/EMPTY_PRINT_REQUEST',
  EMPTY_PRINT_SUCCESS: 'INVESTMENT_QUESTIONNAIRE/EMPTY_PRINT_SUCCES',
  EMPTY_PRINT_FAILURE: 'INVESTMENT_QUESTIONNAIRE/EMPTY_PRINT_FAILURE',
  EMPTY_PRINT_DATA_REQUEST: 'INVESTMENT_QUESTIONNAIRE/EMPTY_PRINT_DATA_REQUEST',
  EMPTY_PRINT_DATA_SUCCESS: 'INVESTMENT_QUESTIONNAIRE/EMPTY_PRINT_DATA_SUCCES',
  UPDATE_MODEL: 'INVESTMENT_QUESTIONNAIRE/UPDATE_MODEL',
  SET_INITIAL_VALUES: 'INVESTMENT_QUESTIONNAIRE/SET_INITIAL_VALUES',
};

export type Model = {
  result?: InvestmentQuestionnaireResultType; // evaluation result
  evaluationPrintSend?: PrintSend;
  emptyPrintSend?: PrintSend;
};

export interface InvestmentQuestionnaireState extends ReducerState {
  isLoading: boolean;
  error: ErrorType | undefined | null;
  model: Model;
  questionnaire: QuestionnaireProps | undefined | null;
  questionnaireId: string | undefined | null;
}

export const initialState = {
  isLoading: false,
  error: null,
  model: {},
  questionnaire: null,
  questionnaireId: null,
};

const investmentQuestionnaire = (
  state: InvestmentQuestionnaireState = initialState,
  { type, payload }: ReducerParams,
): InvestmentQuestionnaireState => {
  switch (type) {
    case actionTypes.EVALUATE_REQUEST:
    case actionTypes.EVALUATION_SEND_REQUEST:
    case actionTypes.EVALUATION_PRINT_REQUEST:
    case actionTypes.EVALUATION_PRINT_DATA_REQUEST:
    case actionTypes.EMPTY_SEND_REQUEST:
    case actionTypes.EMPTY_PRINT_REQUEST:
    case actionTypes.EMPTY_PRINT_DATA_REQUEST:
    case actionTypes.CREATE_REQUEST:
    case actionTypes.GET_QUESTION_REQUEST:
    case actionTypes.SEND_ANSWERS_REQUEST:
      // case actionTypes.EVALUATE_COMPLETED_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        questionnaire: null,
        questionnaireId: payload.questionnaireId,
        error: null,
      };

    case actionTypes.GET_QUESTION_SUCCESS:
      return {
        ...state,
        questionnaire: payload,
        isLoading: false,
        error: null,
      };

    case actionTypes.SEND_ANSWERS_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case actionTypes.EVALUATE_SUCCESS:
      return {
        ...state,
        model: {
          ...state.model,
          ...payload,
        },
        isLoading: false,
        error: null,
      };
    case actionTypes.EVALUATION_SEND_SUCCESS:
      return {
        ...state,
        model: {
          ...state.model,
          evaluationPrintSend: {
            ...state.model.evaluationPrintSend,
            sendDateTime: payload,
          },
        },
        isLoading: false,
        error: null,
      };
    case actionTypes.EVALUATION_PRINT_SUCCESS:
      return {
        ...state,
        model: {
          ...state.model,
          evaluationPrintSend: {
            ...state.model.evaluationPrintSend,
            printDateTime: payload,
          },
        },
        isLoading: false,
        error: null,
      };
    case actionTypes.EMPTY_SEND_SUCCESS:
      return {
        ...state,
        model: {
          ...state.model,
          emptyPrintSend: {
            ...state.model.emptyPrintSend,
            sendDateTime: payload,
          },
        },
        isLoading: false,
        error: null,
      };
    case actionTypes.EMPTY_PRINT_SUCCESS:
      return {
        ...state,
        model: {
          ...state.model,
          emptyPrintSend: {
            ...state.model.emptyPrintSend,
            printDateTime: payload,
          },
        },
        isLoading: false,
        error: null,
      };
    case actionTypes.EVALUATE_FAILURE:
    case actionTypes.EVALUATION_SEND_FAILURE:
    case actionTypes.EVALUATION_PRINT_FAILURE:
    case actionTypes.EMPTY_SEND_FAILURE:
    case actionTypes.EMPTY_PRINT_FAILURE:
    case actionTypes.CREATE_FAILURE:
    case actionTypes.GET_QUESTION_FAILURE:
    case actionTypes.SEND_ANSWERS_FAILURE:
      // case actionTypes.EVALUATE_COMPLETED_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.UPDATE_MODEL:
      return {
        ...state,
        model: {
          ...state.model,
          ...payload,
        },
      };
    case actionTypes.SET_INITIAL_VALUES:
    case contractActionTypes.RESET:
      return initialState;
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default investmentQuestionnaire;

export const actions = {
  stopLoading: () => ({
    type: actionTypes.STOP_LOADING,
  }),
  // Create new questionnaire - blank questions
  createRequest: (questionnaireType: BranchIdTypeEnum, clientCardId: string) => ({
    type: actionTypes.CREATE_REQUEST,
    questionnaireType,
  }),
  createSuccess: (questionnaireId: string) => ({
    type: actionTypes.CREATE_SUCCESS,
    payload: { questionnaireId },
  }),
  createFailure: (error: ErrorType) => ({
    type: actionTypes.CREATE_FAILURE,
    payload: error,
  }),

  // Get question data for given section (step)
  getQuestionDataRequest: (questionnaireType: BranchIdTypeEnum, section?: number, prev?: boolean) => ({
    type: actionTypes.GET_QUESTION_REQUEST,
    questionnaireType,
    section,
    prev,
  }),
  getQuestionDataSuccess: (questionnaire: QuestionnaireProps) => ({
    type: actionTypes.GET_QUESTION_SUCCESS,
    payload: questionnaire,
  }),
  getQuestionDataFailure: (error: ErrorType) => ({
    type: actionTypes.GET_QUESTION_FAILURE,
    payload: error,
  }),

  // Send answers to backned
  sendAnswersRequest: (questionnaireType: BranchIdTypeEnum, answers?: QuestionnaireAnswerProps, isLast?: boolean) => ({
    type: actionTypes.SEND_ANSWERS_REQUEST,
    questionnaireType,
    answers,
    isLast,
  }),
  sendAnswersSuccess: (questionnaire: QuestionnaireProps) => ({
    type: actionTypes.SEND_ANSWERS_SUCCESS,
  }),
  sendAnswersFailure: (error: ErrorType) => ({
    type: actionTypes.SEND_ANSWERS_FAILURE,
    payload: error,
  }),

  // get completed questionnaire
  evaluateCompletedRequest: (questionnaireId: string) => ({
    type: actionTypes.EVALUATE_COMPLETED_REQUEST,
    questionnaireId,
  }),
  evaluateCompletedSuccess: (result: any) => ({
    type: actionTypes.EVALUATE_COMPLETED_SUCCESS,
    result,
  }),
  evaluateCompletedFailure: (error: ErrorType) => ({
    type: actionTypes.EVALUATE_COMPLETED_FAILURE,
    payload: error,
  }),

  // evaluate questionnaire
  evaluateRequest: (questionnaireId?: string) => ({
    type: actionTypes.EVALUATE_REQUEST,
    questionnaireId,
  }),
  evaluateSuccess: (result: any) => ({
    type: actionTypes.EVALUATE_SUCCESS,
    payload: { result },
  }),
  evaluateFailure: (error: ErrorType) => ({
    type: actionTypes.EVALUATE_FAILURE,
    payload: error,
  }),
  evaluationSendRequest: () => ({
    type: actionTypes.EVALUATION_SEND_REQUEST,
  }),
  evaluationSendSuccess: (timestamp: string) => ({
    type: actionTypes.EVALUATION_SEND_SUCCESS,
    payload: timestamp,
  }),
  evaluationSendFailure: (error: ErrorType) => ({
    type: actionTypes.EVALUATION_SEND_FAILURE,
    payload: error,
  }),
  evaluationPrintRequest: () => ({
    type: actionTypes.EVALUATION_PRINT_REQUEST,
  }),
  evaluationPrintSuccess: (timestamp: string) => ({
    type: actionTypes.EVALUATION_PRINT_SUCCESS,
    payload: timestamp,
  }),
  evaluationPrintFailure: (error: ErrorType) => ({
    type: actionTypes.EVALUATION_PRINT_FAILURE,
    payload: error,
  }),
  evaluationPrintDataRequest: () => ({
    type: actionTypes.EVALUATION_PRINT_DATA_REQUEST,
  }),
  emptySendRequest: () => ({
    type: actionTypes.EMPTY_SEND_REQUEST,
  }),
  emptySendSuccess: (timestamp: string) => ({
    type: actionTypes.EMPTY_SEND_SUCCESS,
    payload: timestamp,
  }),
  emptySendFailure: (error: ErrorType) => ({
    type: actionTypes.EMPTY_SEND_FAILURE,
    payload: error,
  }),
  emptyPrintRequest: () => ({
    type: actionTypes.EMPTY_PRINT_REQUEST,
  }),
  emptyPrintSuccess: (timestamp: string) => ({
    type: actionTypes.EMPTY_PRINT_SUCCESS,
    payload: timestamp,
  }),
  emptyPrintFailure: (error: ErrorType) => ({
    type: actionTypes.EMPTY_PRINT_FAILURE,
    payload: error,
  }),
  emptyPrintDataRequest: () => ({
    type: actionTypes.EMPTY_PRINT_DATA_REQUEST,
  }),
  updateModel: (model: Model) => ({
    type: actionTypes.UPDATE_MODEL,
    payload: model,
  }),
  setInitialValues: () => ({
    type: actionTypes.SET_INITIAL_VALUES,
  }),
};

export const getQuestionnaireId = (state: any) => state.contract.investmentQuestionnaire.questionnaireId;
export const getQuestionnaireSection = (state: any) => {
  const questionnaire = state.contract.investmentQuestionnaire.questionnaire;
  return questionnaire ? questionnaire.section : 1;
};
