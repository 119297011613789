import { handleRequest } from 'helpers/handleRequest';
import { call, put } from 'redux-saga/effects';
import { actions } from 'ducks/microsite/coreData';
import { api } from 'constants/api';
import { paramsToUrlMapper } from 'helpers/helpers';

export function* get(action: { payload: { link: string } }): any {
  try {
    const data = yield call(handleRequest, paramsToUrlMapper(api.MICROSITE.LOAD_CORE_DATA, action.payload), {
      auth: false,
    });

    yield put(actions.getSuccess(data));
  } catch (e: any) {
    yield put(actions.getFailure(e));
  }
}
