function pad(num: number, size: number): string {
  let s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
}

export function gtmPush(data: any, time = false) {
  if (time) {
    const date = new Date(),
      year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate();
    const hours = date.getHours(),
      mins = date.getMinutes(),
      secs = date.getSeconds();
    data.time = `${pad(year, 4)}${pad(month, 2)}${pad(day, 2)}${pad(hours, 2)}${pad(mins, 2)}${pad(secs, 2)}`;
  }
  window.dataLayer.push(data);
}
