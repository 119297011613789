import React from 'react';
import TABLE_DATA from './data/help2.tableData.json';
import TABLE_CELLS from './data/help2.tableCells.json';
import renderHTML from 'react-render-html';

type RowDataProps = {
  highlightedRow?: boolean;
  cellsData: string[];
  highlightedCells?: number[];
};

type DataProps = {
  caption: string;
  rows: RowDataProps[];
};

export const Help2Table = () => {
  return (
    <div className="Modal__scrollable-content">
      <table className="list HelpModalTable">
        <thead>
          <tr>
            {TABLE_CELLS.map((cell, i) => (
              <th className="Table__cell" key={`theader-${i}`}>
                <div>{renderHTML(cell.label)}</div>
              </th>
            ))}
          </tr>
        </thead>

        {TABLE_DATA.map((data: DataProps, i) => (
          <TableData {...data} key={`data-${i}`} />
        ))}
      </table>
    </div>
  );
};

const SectionCaption = ({ caption }: { caption: React.ReactNode }) => (
  <tr>
    <th colSpan={TABLE_CELLS.length} className="_caption">
      {caption}
    </th>
  </tr>
);

const TableData = (props: DataProps) => {
  return (
    <tbody>
      <SectionCaption caption={props.caption} />

      {props.rows.map((row, rowI) => (
        <tr key={`table-row-${rowI}`} className={row.highlightedRow ? 'highlighted' : undefined}>
          {row.cellsData.map((td, tdI) => {
            const highlightedCell = row.highlightedCells && row.highlightedCells.includes(tdI);
            return (
              <td key={`td-${rowI}-${tdI}`} className={highlightedCell ? '_highlightedCell' : undefined} data-cnt={td}>
                {td}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
};
