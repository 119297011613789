import { pluralize } from 'helpers/helpers';

export const decimalParser = (value?: string) =>
  value
    ? parseFloat(
        value
          .toString()
          .replace(',', '.')
          .replace(/[^0-9.]/g, ''),
      )
    : '';

export const integerParser = (value?: string | number) =>
  value ? parseInt(value.toString().replace(/[^0-9]/g, ''), 10) : '';

export const currencyFormatter = (value?: number | string, currency?: string, additionalOptions?: Object): string =>
  value == null
    ? ''
    : parseFloat(value as string).toLocaleString('cs', {
        style: 'currency',
        currency: currency ? currency : 'CZK',
        ...(additionalOptions ? additionalOptions : {}),
      });

export const currencyChangeFormatter = (
  value: number | string | undefined,
  change: number,
  currency?: string,
  additionalOptions?: Object,
) =>
  [
    parseFloat(value as string) > 0 && '+',
    currencyFormatter(value, currency, additionalOptions),
    change != null && ' (',
    change > 0 && '+',
    change != null && decimalFormatter(change, '%'),
    change != null && ')',
  ]
    .filter(Boolean)
    .join('');

export const decimalFormatter = (value?: number | string, suffix?: string, additionalOptions?: Object): string =>
  value == null
    ? ''
    : `${parseFloat(value as string).toLocaleString('cs', {
        maximumFractionDigits: 2,
        ...additionalOptions,
      })}${suffix ? '\u00A0' + suffix : ''}`;

export const yearsMonthsFormatter = (value?: number | string): string => {
  if (!value) {
    return '';
  }
  let y: string = yearsExtractor(value);
  let m: string = monthsExtractor(value);
  return y + (y ? ' ' : '') + m;
};

export const yearsExtractor = (value?: number | string): string => {
  if (!value) {
    return '';
  }
  let val: number = parseFloat(value as string);
  let y: number = Math.floor(val / 12);
  return y > 0 ? `${y} ${pluralize(y, 'rok', 'roky', 'let')}` : '';
};

export const monthsExtractor = (value?: number | string): string => {
  if (!value) {
    return '';
  }
  let val: number = parseFloat(value as string);
  let m: number = Math.round(val % 12);
  return m > 0 ? `${m} ${pluralize(m, 'měsíc', 'měsíce', 'měsíců')}` : '';
};
