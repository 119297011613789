import React from 'react';

import Message from 'components/Message/Message';

export default class PageNotFound extends React.PureComponent {
  render() {
    return (
      <div className="content-box">
        <Message type="info">Bohužel, tato stránka neexistuje.</Message>
      </div>
    );
  }
}
