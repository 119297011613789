import React from 'react';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';

import { store, persistor } from 'micrositeStore';
import { Loading, onBeforeLift } from 'helpers/persistorHelpers';

import LoadingComponent from 'components/LoadingComponent';
import './MicrositeAppRenderer.css';

const AsyncMicrosite = Loadable({
  loader: () => import('containers/microsite/Index'),
  loading: LoadingComponent,
});

class MicrositeAppRenderer extends React.Component<any, {}> {
  render = () => (
    <Provider store={store}>
      <PersistGate persistor={persistor} onBeforeLift={onBeforeLift} loading={<Loading />}>
        <Router>
          <div className="MicrositeApp">
            <Route path="/microsite" component={AsyncMicrosite as any} />
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default MicrositeAppRenderer;
