import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import Loadable from 'react-loadable';

import LoadingComponent from 'components/LoadingComponent';
import PageNoPermissions from 'containers/common/PageNoPermissions/PageNoPermissions';

import { UserPermissionsProps } from 'helpers/types';

const AsyncLogin = Loadable({
  loader: () => import('containers/Login'),
  loading: LoadingComponent,
});

interface PrivateRouteProps extends RouteProps {
  component: NonNullable<RouteProps['component']>;
  user?: Record<string, any>;
  permissions: UserPermissionsProps;
  access?: keyof UserPermissionsProps;
}

const PrivateRoute = ({ component, user, permissions, access, ...rest }: PrivateRouteProps) => {
  const Component = !access || (access && permissions && permissions[access]) ? component : PageNoPermissions;

  return (
    <Route
      {...rest}
      render={(props) => {
        return !user || user.expired === undefined || user.expired ? <AsyncLogin /> : <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
